import { useSearchParams } from "react-router-dom";

export function useSearchState<T>(key: string, initialValue: T) {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchKeyValue = searchParams.get(key);
  const value = searchKeyValue ? JSON.parse(searchKeyValue) : initialValue;

  const setValue = (newValue: T) => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev.toString());
      if (newValue === null) {
        params.delete(key);
        return params;
      } else {
        params.set(key, JSON.stringify(newValue));
      }
      return params;
    });
  };

  return [value, setValue] as const;
}
