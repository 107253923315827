import { FieldValues, Path, useFormContext } from "react-hook-form";
import TextInput from "../controls/TextInput.js";

type TextInputFormControlProps<T> = {
  name: Path<T>;
  label: string;
  helpText?: string;
};

export function TextInputFormControl<T extends FieldValues>(
  props: TextInputFormControlProps<T>
) {
  const formContext = useFormContext<T>();
  formContext.formState.errors; //TODO... this seems required to get errors to show up???
  const error = formContext.getFieldState(props.name).error;
  return (
    <TextInput
      label={props.label}
      helpText={props.helpText}
      {...formContext.register(props.name)}
      error={error}
    />
  );
}

export default TextInputFormControl;
