import {
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Certificate } from "stocksmith";
import { ShareholderProfile } from "../lib/useShareholders.js";
import { Link } from "react-router-dom";
import { fullName } from "@hobson/utils";

type CertificateWithShareholder = {
  certificate: Certificate;
  shareholder: ShareholderProfile;
};

type CertificatesTableProps = {
  data: CertificateWithShareholder[];
  viewLinkPath: (id: number) => string;
} & TableProps;

export function CertificatesTable({
  data,
  viewLinkPath,
  ...tableProps
}: CertificatesTableProps) {
  return (
    <Table {...tableProps}>
      <Thead>
        <Tr>
          <Th>Certificate #</Th>
          <Th>Shareholder</Th>
          <Th># Shares</Th>
          <Th>Date Issued</Th>
          <Th>Date Canceled</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map(({ certificate, shareholder }) => {
          return (
            <Tr key={certificate.number}>
              <Td>
                <Link to={viewLinkPath(certificate.number)}>
                  <Text
                    color="blue.600"
                    _hover={{
                      textDecoration: "underline",
                    }}
                  >
                    {certificate.number}
                  </Text>
                </Link>
              </Td>
              <Td whiteSpace="normal">{fullName(shareholder)}</Td>
              <Td>{certificate.shares}</Td>
              <Td>{certificate?.issuedAt?.formatUSA()}</Td>
              <Td>{certificate?.canceledAt?.formatUSA()}</Td>
              <Td></Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

export default CertificatesTable;
