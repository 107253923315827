import { useCurrentCompany } from "../utils/useCompany.js";
import {
  useShareholders,
  useShareholdersWithCompanyData,
} from "./useShareholders.js";

export function useCompanyData() {
  const shook = useShareholders();
  const chook = useCurrentCompany();
  const shareholders = useShareholdersWithCompanyData(
    shook.shareholders,
    chook.company
  );

  return {
    ...chook,
    ...shook,
    shareholders,
  };
}
