import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import ChakraDatepickerInput, {
  ChakraDatepickerInputProps,
} from "./ChakraDatepickerInput.js";

export type DatePickerInputProps = ChakraDatepickerInputProps & {
  name: string;
  helpText?: string;
  label: string;
  error?: {
    message?: string;
  };
};

export const DatePickerInput = (props: DatePickerInputProps) => {
  const { helpText, label, error, name, ...rest } = props;

  return (
    <FormControl isInvalid={Boolean(error)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <ChakraDatepickerInput id={name} {...rest} />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
};

export default DatePickerInput;
