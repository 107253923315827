import { Box, HStack, Stack, Text } from "@chakra-ui/react";
import { CertificateConfigView } from "../components/CertificateConfigView.js";
import ReportViewer from "../reports/pdf/ReportViewer.js";
import { certificateReport } from "../reports/pdf/certificate.js";
import { useCallback, useMemo, useState } from "react";
import { useCertificateConfig } from "../lib/useCertificateConfig.js";
import { useShareholders } from "../lib/useShareholders.js";
import { useCurrentCompany } from "../utils/useCompany.js";

export function SettingsPage() {
  const certificateNumber = 630;

  const [configKey, setConfigKey] = useState(1);

  const onSave = useCallback(() => {
    setConfigKey((key) => key + 1);
  }, [setConfigKey]);

  return (
    <Stack>
      <Text>SettingsPage</Text>
      <Stack>
        <Box flex={1}>
          <CertificateConfigView onSave={onSave} />
        </Box>
        <Box flex={1}>
          <CertificatePdfView
            key={configKey}
            certificateNumber={certificateNumber}
          />
        </Box>
      </Stack>
    </Stack>
  );
}

const CertificatePdfView = ({
  certificateNumber,
}: {
  certificateNumber: number;
}) => {
  const { company } = useCurrentCompany();
  const { profilesById } = useShareholders();
  const certificate = company.certificates[certificateNumber];
  const { config } = useCertificateConfig();

  const shareholder = profilesById[certificate?.shareholderId];
  const labelObjects = config?.labelObjects;
  const args = useMemo(() => {
    return {
      certificate,
      shareholder,
      labelObjects: labelObjects,
      showImage: true,
    };
  }, [certificate, shareholder, labelObjects]);

  return (
    <Box>
      {/* <Box>
        <pre>{JSON.stringify(args, null, 2)}</pre>
      </Box> */}
      <Box>
        <ReportViewer
          generateReport={certificateReport}
          args={args}
          height="800px"
          filename={`certificate-example.pdf`}
        />
      </Box>
    </Box>
  );
};

export default SettingsPage;
