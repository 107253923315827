import { FieldValues, Path, useFormContext } from "react-hook-form";
import SelectInput, { SelectOption } from "../controls/SelectInput.js";

type SelectInputFormControlProps<T> = {
  name: Path<T>;
  options: SelectOption[];
  label: string;
};

export function SelectInputFormControl<T extends FieldValues>(
  props: SelectInputFormControlProps<T>
) {
  const formContext = useFormContext<T>();
  formContext.formState.errors; //TODO... this seems required to get errors to show up???
  const error = formContext.getFieldState(props.name).error;
  return (
    <SelectInput
      label={props.label}
      {...formContext.register(props.name)}
      error={error}
      options={props.options}
    />
  );
}

export default SelectInputFormControl;
