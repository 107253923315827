// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
//
// Writing these report files in js since typescript typing for write-to-excel package just
// doesn't seem to work right
import { Counter, PlainDate, fullName } from "@hobson/utils";
import writeXlsxFile from "write-excel-file";

export async function createCertificatesReport(data) {
  const schemas = [
    {
      header: "Number",
      value: (row) => row.certificate.number.toString(),
    },
    {
      header: "Shareholder ID",
      value: (row) => row.shareholder.id,
    },
    {
      header: "Shareholder Name",
      value: (row) => fullName(row.shareholder),
    },
    {
      header: "Number of Shares",
      value: (row) => row.certificate.shares,
      type: Number,
    },
    {
      header: "Date Issued",
      value: (row) => row.certificate.issuedAt.toString(),
    },
    {
      header: "Date Canceled",
      value: (row) => row.certificate.canceledAt?.toString(),
    },
  ];

  const colLengths = new Counter(0);

  const headers = schemas.map((s) => {
    colLengths.set(s.header, s.header.length);
    return {
      type: String,
      value: s.header,
      fontWeight: "bold",
    };
  });

  const dataRows = data.map((shareholder) => {
    return schemas.map((schema) => {
      const value = schema.value(shareholder) as string;
      const valLength = value?.length || 0;
      if (valLength > colLengths.get(schema.header)) {
        colLengths.set(schema.header, valLength);
      }
      return {
        type: schema.type || String,
        value,
      };
    });
  });

  const colConfigs = schemas.map((schema) => {
    const colLength = colLengths.get(schema.header);
    return {
      width: colLength,
    };
  });

  const date = new PlainDate();
  await writeXlsxFile([headers, ...dataRows], {
    columns: colConfigs,
    fileName: `certificates_${date.toString()}.xlsx`,
  });
}
