import { PDFDocument } from "pdf-lib";
import { useEffect, useState } from "react";
// import { useCertificateConfig } from "../../lib/useCertificateConfig.js";
// import { useIsMounted } from "../../lib/useIsMounted.js";
// import { useCurrentCompany } from "../../utils/useCompany.js";
// import { certificateReport } from "./certificate.js";
// import { renderReportToBlobStream } from "./pdfUtils.js";

// export const useCertificatePdf2 = (certificateNumber: number) => {
//   const { company } = useCurrentCompany();
//   const { config } = useCertificateConfig();
//   const isMounted = useIsMounted();
//   const certificate = company.certificates[certificateNumber];
//   const [blobUrl, setBlobUrl] = useState<string | null>(null);

//   useEffect(() => {
//     const generateReport = async () => {
//       if (!certificate || !config) return null;
//       const data = {
//         certificate,
//         certificateConfig: config.labelObjects,
//         backgroundImageUrl: CertificateImageUrl,
//       };
//       const blob = await renderReportToBlobStream(certificateReport, data);
//       if (isMounted()) setBlobUrl(blob);
//     };
//     generateReport();
//     return undefined;
//   }, [certificate, config, isMounted]);

//   return blobUrl;
// };

export const generatePdfReport = async (report: any, args: any) => {
  const doc = await report(args);
  if (doc instanceof PDFDocument) {
    const pdfBytes = await doc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  } else {
    const blob = doc.output("blob");
    // const dataUrl = doc.output("datauristring");
    // return dataUrl;
    // console.log(dataUrl);
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  }
};

export const useReport = (report: any, args: any) => {
  const [blobUrl, setBlobUrl] = useState<string | null>(null);
  useEffect(() => {
    const exec = async () => {
      setBlobUrl(await generatePdfReport(report, args));
    };
    exec();
  }, [report, args]);
  return blobUrl;
};
