import { Box, Card, Container, Heading, Stack } from "@chakra-ui/react";

export function NotFoundPage() {
  const color = "green.500";

  return (
    <Box
      flex={1}
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={{ base: 0, md: 10 }}
    >
      <Container>
        <Card p={{ base: 3, md: 8 }}>
          <Stack textAlign="center" width="100%">
            <Heading size="xl" color={color}>
              404 Not Found
            </Heading>
            <Heading size="md" color={color} my={4}>
              Sorry, we can't seem to find that page or record. Please
              double-check the URL or alert support.
            </Heading>
          </Stack>
        </Card>
      </Container>
    </Box>
  );
}

export default NotFoundPage;
