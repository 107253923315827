import { As, Button, ButtonProps, HStack, Icon, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

interface NavButtonProps extends ButtonProps {
  icon: As;
  label: string;
  to: string;
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, ...buttonProps } = props;
  return (
    <Button
      variant="ghost-on-accent"
      justifyContent="start"
      {...buttonProps}
      as={NavLink}
      to={props.to}
    >
      <HStack spacing="3">
        <Icon as={icon} boxSize="6" color="on-accent-subtle" />
        <Text>{label}</Text>
      </HStack>
    </Button>
  );
};
