import {
  Company,
  ShareholderWithCompanyStats,
  mergeShareholderData,
} from "stocksmith";
import { RouterOutput, trpc } from "../utils/trpcClient.js";
import { useMemo } from "react";
import { useToast } from "@chakra-ui/react";
import { Link } from "../components/Link.js";
import { useNavigate } from "react-router-dom";
import { fullName } from "@hobson/utils";

export function useShareholders() {
  const trpcCtx = trpc.useContext();
  const toast = useToast();
  const shareholderQuery = trpc.shareholders.list.useQuery(undefined, {
    suspense: true,
  });
  const navigate = useNavigate();

  const addShareholderMutation = trpc.shareholders.add.useMutation({
    onSuccess(shareholder) {
      trpcCtx.shareholders.invalidate();
      const id = shareholder.id;
      toast({
        title: "Shareholder added.",
        description: (
          //Can't just use normal link in toast because it's rendered in a portal outside of react-router context
          <a
            style={{ textDecoration: "underline" }}
            href={`/shareholders/${id}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/shareholders/${id}`);
            }}
          >
            {`View ${fullName(shareholder)}`}
          </a>
        ),
        status: "success",
      });
    },
    onError(error: any) {
      toast({
        title: "Shareholder could not be added.",
        status: "error",
      });
      console.error(error);
    },
  });
  const updateShareholderMutation = trpc.shareholders.update.useMutation({
    onSuccess() {
      trpcCtx.shareholders.invalidate();
      toast({
        title: "Shareholder updated.",
        status: "success",
      });
    },
    onError(error: any) {
      toast({
        title: "Failed to update shareholder.",
        status: "error",
      });
      console.error(error);
    },
  });

  const delShareholderMutation = trpc.shareholders.delete.useMutation({
    onSuccess() {
      trpcCtx.shareholders.invalidate();
      toast({
        title: "Deleted shareholder.",
        status: "success",
      });
    },
    onError(error: any) {
      toast({
        title: "Could not delete shareholder.",
        status: "error",
      });
      console.error(error);
    },
  });

  const profilesById = useMemo(() => {
    const profilesById: Record<
      string,
      RouterOutput["shareholders"]["list"][0]
    > = {};
    (shareholderQuery.data || []).forEach((profile) => {
      profilesById[profile.id] = profile;
    });
    return profilesById;
  }, [shareholderQuery.data]);

  return {
    shareholders: shareholderQuery.data || [],
    profilesById,
    addShareholderMutation,
    updateShareholderMutation,
    delShareholderMutation,
  };
}

export type ShareholderProfile = ShareholderWithCompanyStats<
  RouterOutput["shareholders"]["list"][0]
>;

type ShareholderID = {
  id: string;
};

export function useShareholdersWithCompanyData<T extends ShareholderID>(
  shareholderProfiles: T[],
  company: Company
) {
  const mergedResults = useMemo(() => {
    return mergeShareholderData(shareholderProfiles, company);
  }, [shareholderProfiles, company]);

  return mergedResults;
}
