import { FieldValues, Path, useFormContext } from "react-hook-form";

type HiddenInputFormControlProps<T> = {
  name: Path<T>;
  label: string;
  helpText?: string;
};

export function HiddenInputFormControl<T extends FieldValues>(
  props: HiddenInputFormControlProps<T>
) {
  const formContext = useFormContext<T>();

  return <input {...formContext.register(props.name)} type="hidden" />;
}

export default HiddenInputFormControl;
