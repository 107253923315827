// FIXME this code was copied from old JS app... needs better refactoring to make it better and more readable/typesafe
import { PlainDate, fullName } from "@hobson/utils";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Table, UserOptions } from "jspdf-autotable";
import { Company, Event, ShareholderWithCompanyStats } from "stocksmith";
import { useCompanyData } from "../../lib/useCompanyData.js";
import { ShareholderProfile } from "../../lib/useShareholders.js";
import { useEventsByDate } from "../../utils/useCompany.js";
import ReportViewer from "./ReportViewer.js";

interface jsPDFCustom extends jsPDF {
  autoTable: (options: UserOptions) => void;
  lastAutoTable: Table;
}

type Args = {
  profilesById: Record<string, ShareholderWithCompanyStats<ShareholderProfile>>;
  shareholders: ShareholderWithCompanyStats<ShareholderProfile>[];
  company: Company;
  sortedDateKeys: string[];
  eventsByDate: Record<string, Event[]>;
};

//eslint-disable-next-line react-refresh/only-export-components
export const journalReport = async ({
  profilesById,
  company,
  sortedDateKeys,
  eventsByDate,
}: Args) => {
  const doc = new jsPDF("landscape", "in", [8.5, 11]) as jsPDFCustom;

  doc.setFontSize(18);
  doc.text(`Journal for ${company.name}`, 0.5, 0.5);
  doc.setFontSize(12);
  doc.text(`Generated on ${new Date().toLocaleDateString()}`, 0.5, 0.75);

  const startY = 1;
  let allData = [] as any;
  for (const dateKey of sortedDateKeys) {
    const body = eventsByDate[dateKey]
      .map((event) => {
        if (event.type === "CertificateIssued") {
          const shareholder = profilesById[event.payload.shareholderId];
          return [
            dateKey,
            fullName(shareholder),
            event.payload.certificateNumber.toString(),
            event.payload.shares.toLocaleString(),
            "",
          ];
        } else if (event.type === "CertificateCanceled") {
          const certificate =
            company.certificates[event.payload.certificateNumber];
          const shareholder = profilesById[certificate.shareholderId];
          return [
            dateKey,
            fullName(shareholder),
            event.payload.certificateNumber.toString(),
            "",
            certificate.shares.toLocaleString(),
          ];
        } else {
          return null;
        }
      })
      .filter(Boolean) as string[][];
    allData = [...allData, ...body];
  }

  doc.autoTable({
    bodyStyles: {
      fontSize: 10,
    },
    startY: startY,
    margin: {
      left: 0.5,
      right: 0.5,
      top: 0.5,
      bottom: 0.5,
    },
    head: [["Date", "name", "Cert #", "# Issue", "# Cancel"]],
    body: allData,
    headStyles: {
      fontSize: 10,
      fillColor: [39, 116, 72],
    },
    showFoot: "lastPage",
  });
  // const table = doc.lastAutoTable;
  // const finalY = table.finalY;
  // startY = finalY + 0.25;

  doc.setProperties({
    title: "Journal",
  });

  return doc;
};

export function JournalReport() {
  const { profilesById, company } = useCompanyData();
  const { sortedDateKeys, eventsByDate } = useEventsByDate(company);

  return (
    <ReportViewer
      generateReport={journalReport}
      args={{
        profilesById,
        company,
        sortedDateKeys,
        eventsByDate,
      }}
      filename={`${company.id}_journal_${new PlainDate().toString()}.pdf`}
    />
  );
}
