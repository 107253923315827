import { Box } from "@chakra-ui/react";
import { SignIn } from "@clerk/clerk-react";

export function SignInPage() {
  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <SignIn path="/sign-in" routing="path" />
    </Box>
  );
}

export default SignInPage;
