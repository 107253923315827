import {
  Box,
  Button,
  CloseButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import {
  Calendar,
  CalendarControls,
  CalendarDate,
  CalendarDays,
  CalendarMonth,
  CalendarMonthName,
  CalendarMonths,
  CalendarNextButton,
  CalendarPrevButton,
  CalendarValues,
  CalendarWeek,
} from "@uselessdev/datepicker";
import { format, isValid, isDate } from "date-fns";
import React, { useEffect } from "react";

export type ChakraDatepickerInputProps = {
  value: string;
  clearable?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
};

function isCalendarDate(date: any): date is CalendarDate {
  return typeof date === "number" || isValid(date);
}
function coerceCalendarValue(value: number | Date | CalendarValues) {
  const date = isCalendarDate(value) ? value : value.start;
  if (!date) return null;
  if (typeof date === "number") return new Date(date);
  return date;
}

function strToDate(str?: string | null) {
  if (!str) return undefined;

  const date = new Date(str);
  if (isValid(date)) return date;
  return undefined;
}

export function ChakraDatepickerInput(props: ChakraDatepickerInputProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const calendarRef = React.useRef(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const setValue = (value: Date | null) => {
    if (!inputRef.current) return;
    if (value === null) {
      props.onChange &&
        props.onChange({
          target: {
            value: "",
          },
        } as React.ChangeEvent<HTMLInputElement>);
    }

    if (value && isDate(value) && isValid(value)) {
      // inputRef.current.value = format(value, "MM/dd/yyyy");
      props.onChange &&
        props.onChange({
          target: {
            value: format(value, "MM/dd/yyyy"),
          },
        } as React.ChangeEvent<HTMLInputElement>);
      // props && props.onChange(inputRef.current.value);
    }
  };

  const handleSelectDate = (selectValue: CalendarDate | CalendarValues) => {
    const date = coerceCalendarValue(selectValue);
    setValue(date);
    onClose();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(e);
    if (isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.value = props.value;
  }, [props.value]);

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setValue(null);
  };

  const dateValue = strToDate(props.value);

  useOutsideClick({
    ref: calendarRef,
    handler: onClose,
    enabled: isOpen,
  });

  return (
    <Box>
      <Popover
        placement="auto-start"
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        isLazy
      >
        <PopoverTrigger>
          <Box onClick={onOpen} ref={initialRef}>
            <InputGroup>
              <Input
                ref={inputRef}
                id={props.id}
                placeholder="MM/dd/yyyy"
                onChange={handleInputChange}
                onBlur={props.onBlur}
                value={props.value || ""}
              />

              <InputRightElement width="6rem" justifyContent="flex-end" mr={2}>
                {props.clearable && (
                  <CloseButton onClick={handleClear} mr={2} />
                )}
                <Button h="1.75rem" size="sm" onClick={onOpen}>
                  <CalendarIcon />
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
        </PopoverTrigger>

        <PopoverContent
          p={0}
          w="min-content"
          border="none"
          outline="none"
          _focus={{ boxShadow: "none" }}
          ref={calendarRef}
        >
          <Calendar
            value={{ start: dateValue }}
            onSelectDate={handleSelectDate}
            singleDateSelection
          >
            <PopoverBody p={0}>
              <CalendarControls>
                <CalendarPrevButton />
                <CalendarNextButton />
              </CalendarControls>

              <CalendarMonths>
                <CalendarMonth>
                  <CalendarMonthName />
                  <CalendarWeek />
                  <CalendarDays />
                </CalendarMonth>
              </CalendarMonths>
            </PopoverBody>
          </Calendar>
        </PopoverContent>
      </Popover>
    </Box>
  );
}

export default ChakraDatepickerInput;
