import { z } from "zod";
import * as schemas from "./CommandSchemas.js";

export const zCommandInputSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("CreateCompany"),
    payload: schemas.CreateCompanySchema,
  }),
  z.object({
    type: z.literal("BuyShares"),
    payload: schemas.BuySharesSchema,
  }),
  z.object({
    type: z.literal("BuyCertificate"),
    payload: schemas.BuyCertificateSchema,
  }),
  z.object({
    type: z.literal("SellShares"),
    payload: schemas.SellSharesSchema,
  }),
  z.object({
    type: z.literal("ImportCertificate"),
    payload: schemas.ImportCertificateSchema,
  }),
  z.object({
    type: z.literal("ChangeAuthorizedShares"),
    payload: schemas.ChangeAuthorizedSharesSchema,
  }),
  z.object({
    type: z.literal("ForwardSplitStock"),
    payload: schemas.ForwardSplitStockSchema,
  }),
  z.object({
    type: z.literal("ReissueCertificate"),
    payload: schemas.ReissueCertificateSchema,
  }),
  z.object({
    type: z.literal("TransferShares"),
    payload: schemas.TransferSharesSchema,
  }),
  z.object({
    type: z.literal("VoidCertificate"),
    payload: schemas.VoidCertificateSchema,
  }),
  z.object({
    type: z.literal("SetIssuedShares"),
    payload: schemas.SetIssuedSharesSchema,
  }),
]);

export type CommandInput = z.input<typeof zCommandInputSchema>;
export type CommandOutput = z.output<typeof zCommandInputSchema>;
export type ParsedCommand = CommandOutput;

export function createCommand(props: z.input<typeof zCommandInputSchema>) {
  return props;
}
