import { superjson } from "@hobson/utils";
import { QueryCache, QueryClient } from "@tanstack/react-query";
import {
  createTRPCProxyClient,
  httpBatchLink,
  HTTPHeaders,
  TRPCClientError,
} from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import type { AppRouter } from "api";
import { createTRPCJotai } from "jotai-trpc";
import { createContext, useState } from "react";

export type RouterInput = inferRouterInputs<AppRouter>;
export type RouterOutput = inferRouterOutputs<AppRouter>;

const proto = location.protocol;
const hostname = location.hostname;
// const isDev = window.location.port === "3000";
// const devPattern = /^(localhost|(?:\d{1,3}\.){3}\d{1,3})$/;
// const isDev = devPattern.test(hostname);

const port = location.port;
const trpcPath = "/api/trpc";

const trpcUrl = `${proto}//${hostname}:${port}${trpcPath}`;
console.log("using url: ", trpcUrl);

// TODO: disabling warning on react-refresh here... need to research more
// and figure out how big a deal this is for components like this, feels annoying to split
// out the provider from our context definitions
/* eslint react-refresh/only-export-components:0 */

export const reactQueryContext = createContext<QueryClient | undefined>(
  undefined
);

async function getHeaders(): Promise<HTTPHeaders> {
  try {
    // const token = authStore.getState().token;
    return {
      // Authorization: undefined,
      // "Content-Type": "application/json",
    };
  } catch (e) {
    console.log("could not get id token");
    console.log(e);
    // auth.signOut();
  }
  return {};
}

const trpcOptions = {
  transformer: superjson,
  links: [
    httpBatchLink({
      url: trpcUrl,
      // You can pass any HTTP headers you wish here
      headers: getHeaders,
      fetch: (url, options) => {
        console.log("fetching with options: ", options);
        return fetch(url, {
          ...options,
          credentials: "include",
        });
      },
    }),
  ],
};

export const jotaiTrpc = createTRPCJotai<AppRouter>({
  ...trpcOptions,
});

export const trpcProxyClient = createTRPCProxyClient<AppRouter>({
  ...trpcOptions,
});

export const trpc = createTRPCReact<AppRouter>({
  reactQueryContext,
});

export const TrpcReactProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [queryClient] = useState(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          retry: 1,
          staleTime: 1000 * 2, // Consider data fresh for 10 minutes unless otherwise invalided,
          cacheTime: 1000 * 60 * 60, //cache data for up to 1 hour,
        },
      },
      queryCache: new QueryCache({
        onError: (error, query) => {
          if (error instanceof TRPCClientError) {
            console.log("TRPC Error communicating with", trpcUrl);
            if (error.data?.httpStatus === 401) {
              console.log("Auth is invalid, signing out");
              query.setData(null);
              // TODO, sign out?
              // logout();
            }
          } else {
            console.log("Non-TRPC Error", error);
          }
        },
      }),
    });
  });

  const [trpcClient] = useState(() => {
    return trpc.createClient({
      ...trpcOptions,
    });
  });

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <reactQueryContext.Provider value={queryClient}>
        {children}
      </reactQueryContext.Provider>
    </trpc.Provider>
  );
};
