import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Stack,
} from "@chakra-ui/react";
import { AnimateIn } from "../animations/AnimateIn.js";
import Address from "../components/Address.js";
import { Stat } from "../components/Stat.js";
import CompanyProfileForm from "../forms/CompanyProfileForm.js";
import { useSearchState } from "../lib/useSearchState.js";
import { useCompanies, useCurrentCompany } from "../utils/useCompany.js";
import CompanyStats from "../components/CompanyStats.js";

//TODO, typescript makes this quick mapping between short-names (e.g. LLC) and display-names
// (e.g. Limited Liability Company) pretty annoying. Wonder if there is a better pattern for this?
const typeMap = {
  "C-Corp": "Subchapter (C) Corporation",
  "S-Corp": "Subchapter (S) Corporation",
  LLC: "Limited Liability Company",
} as Record<string, string>;

const typeMapping = (type?: string | null) => {
  if (!type) return type;
  return typeMap[type] || type;
};

export function CompanyPage() {
  const { companyId, company, companyProfile } = useCurrentCompany();
  const { updateCompanyMutation } = useCompanies();

  const [isEditing, setEditing] = useSearchState("edit", false);
  const toggleEditing = () => setEditing(!isEditing);

  // const profile = companyProfile && companyProfile.filter(Boolean);
  const defaultValues = companyProfile
    ? Object.fromEntries(
        Object.entries(companyProfile).filter(([_, v]) => v != null)
      )
    : {};

  return (
    <Stack>
      <Box position="relative" mt={4}>
        <AnimateIn isVisible={!isEditing} duration={0.3}>
          <Box padding={2}>
            <Flex justifyContent="flex-end" pr={4}>
              <Button onClick={toggleEditing}>Edit</Button>
            </Flex>
            <Grid templateColumns="repeat(2, 1fr)" gap={6} p={4}>
              <Stat label="Name" value={companyProfile?.name} />
              <Stat label="ID" value={companyId} />
              <Stat label="Type" value={typeMapping(companyProfile?.type)} />
              <Stat
                label="Established"
                value={company?.dateEstablished?.toString() || "Unknown"}
              />
              <Stat
                label="State of Incorporation"
                value={companyProfile?.incorporationState}
              />
              <Stat
                label="Address"
                value={
                  <Address
                    address={{
                      address1: companyProfile?.addressLine1,
                      address2: companyProfile?.addressLine2,
                      city: companyProfile?.city,
                      state: companyProfile?.state,
                      zip: companyProfile?.zip,
                    }}
                  />
                }
              />
            </Grid>
            <Divider my="6" />
            <CompanyStats company={company} />
          </Box>
        </AnimateIn>
        <AnimateIn isVisible={isEditing} duration={0.3}>
          <Container>
            <Box padding={2} border="solid 1px gray">
              <Flex justifyContent="flex-end">
                <Button onClick={toggleEditing}>Cancel</Button>
              </Flex>
              <CompanyProfileForm
                key={companyId}
                onSubmit={async (values) => {
                  await updateCompanyMutation.mutateAsync({
                    id: companyId,
                    data: values,
                  });
                  setEditing(false);
                }}
                defaultValues={defaultValues}
              />
            </Box>
          </Container>
        </AnimateIn>
      </Box>
    </Stack>
  );
}

export default CompanyPage;
