import { z } from "zod";

export const companyProfileSchema = z.object({
  name: z.string().nonempty({ message: "Company name is required" }),
  type: z.string().nonempty({ message: "Company type is required" }),
  incorporationState: z.string().optional().or(z.literal("")),
  addressLine1: z.string().nonempty({ message: "Address Line 1 is required" }),
  addressLine2: z.string(),
  city: z.string().nonempty({ message: "City is required" }),
  state: z.string().nonempty({ message: "State is required" }),
  zip: z.string().nonempty({ message: "Zip is required" }),
});
