//React hook to programmatically manage a unique key for imperitive control over re-rendering

import { useCallback, useState } from "react";

export function useUpdatableKey() {
  const [key, setKey] = useState(0);
  const updateKey = useCallback(() => {
    setKey((k) => k + (1 % 8));
  }, []);
  return [key, updateKey] as const;
}
