import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { useGlobalDisclosure } from "../lib/globals.js";
import { useCompanies } from "../utils/useCompany.js";

export function SelectCompanyModal() {
  const { setCurrentCompanyId, companies } = useCompanies();
  const { isOpen, onClose } = useGlobalDisclosure("selectCompanyModal");
  const { onOpen: onOpenAddCompany } = useGlobalDisclosure("addCompanyModal");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Company</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          <Box mb={4}>
            <Stack>
              {companies.map((company) => {
                return (
                  <Button
                    key={company.id}
                    onClick={() => {
                      setCurrentCompanyId(company.id);
                      onClose();
                    }}
                  >
                    {company.name}
                  </Button>
                );
              })}
            </Stack>
          </Box>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Button onClick={onOpenAddCompany}>Add New Company</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SelectCompanyModal;
