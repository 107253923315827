import { TemplateData, TemplateHandler } from "easy-template-x";

import { useMemo } from "react";
import { Company, ShareholderWithCompanyStats } from "stocksmith";
import { useCompanyData } from "../../lib/useCompanyData.js";
import { ShareholderProfile } from "../../lib/useShareholders.js";
import { fullName, addressStr, PlainDate } from "@hobson/utils";
import { z } from "zod";
import { useZodForm } from "@hobson/forms";
import { Box, Button, Container, Heading, Stack } from "@chakra-ui/react";
import { startOfMonth } from "date-fns";

const dateRegex = /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
const proxySchema = z.object({
  meeting_date: z
    .string()
    .nonempty({ message: "Date is required" })
    .regex(dateRegex, { message: "Invalid date format, must be MM/dd/yyyy" }),
  shares_held_date: z
    .string()
    .nonempty({ message: "Date is required" })
    .regex(dateRegex, { message: "Invalid date format, must be MM/dd/yyyy" }),
  meeting_location: z.string(),
});

type ProxyData = z.infer<typeof proxySchema>;

type Args = {
  shareholders: ShareholderWithCompanyStats<ShareholderProfile>[];
  company: Company;
  data: ProxyData;
};

//eslint-disable-next-line react-refresh/only-export-components
export const proxyReport = async ({ data, shareholders, company }: Args) => {
  const templateUrl = "/proxy_example.docx";
  const templateBlob = await fetch(templateUrl).then((res) => res.blob());
  const templateData = {
    shareholders: shareholders.map((s) => {
      return {
        page_break: {
          _type: "rawXml",
          xml: "<w:br w:type='page'/>",
          replaceParagraph: false,
        },
        meeting_date: {
          _type: "rawXml",
          xml: dateXml(new Date(data.meeting_date)),
          replaceParagraph: false,
        },
        shares_held_date: {
          _type: "rawXml",
          xml: dateXml(new Date(data.shares_held_date)),
          replaceParagraph: false,
        },
        meeting_location: data.meeting_location,
        company_name: company.name,
        company_address: addressStr(company),
        shareholder_name: fullName(s),
        shareholder_shares: (
          s.ownershipSummary?.totalShares || 0
        ).toLocaleString(),
      };
    }),
  } as TemplateData;
  const handler = new TemplateHandler();
  const doc = await handler.process(templateBlob, templateData);

  return doc;
};

export function ProxyReport() {
  const { shareholders, company } = useCompanyData();

  const filteredShareholders = useMemo(
    () => shareholders.filter((s) => s.isActive),
    [shareholders]
  );

  const zf = useZodForm(proxySchema, {
    defaultValues: {
      meeting_date: new PlainDate(new Date()).formatUSA(),
      shares_held_date: new PlainDate(startOfMonth(new Date())).formatUSA(),
      meeting_location: "via Zoom",
    },
  });

  const generateReport = async (data: ProxyData) => {
    // see: https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link

    // get downloadable url from the blob
    const blob = await proxyReport({
      shareholders: filteredShareholders,
      company,
      data,
    });
    const blobUrl = URL.createObjectURL(blob);

    // create temp link element
    const link = document.createElement("a");
    link.download = `${company.name} Shareholder Proxy ${new PlainDate(
      data.meeting_date
    ).toString()}.docx`;
    link.href = blobUrl;

    // use the link to invoke a download
    document.body.appendChild(link);
    link.click();

    // remove the link
    setTimeout(() => {
      link.remove();
      window.URL.revokeObjectURL(blobUrl);
      // link = null;
    }, 0);
  };
  return (
    <Box>
      <zf.Form
        onSubmit={(data) => {
          generateReport(data);
        }}
      >
        <Container>
          <Heading mb={4}>Proxy Report Generator</Heading>
          <Stack spacing={5}>
            <zf.TextInput name="meeting_location" label="Meeting Location" />
            <zf.DateInput name="meeting_date" label="Meeting Date" clearable />
            <zf.DateInput
              name="shares_held_date"
              label="Shares Held Date"
              clearable
            />
            <Button type="submit" colorScheme="green">
              Download Generated Report
            </Button>
          </Stack>
        </Container>
      </zf.Form>
    </Box>
  );
}

function dateXml(date: Date) {
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();
  const daySuffix =
    day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th";

  return `
    <w:r>
    <w:r>
      <w:t>
        ${month} ${day}
      </w:t>
    </w:r>
    <w:r>
      <w:rPr>
        <w:vertAlign w:val="superscript"/>
      </w:rPr>
    <w:t>${daySuffix}</w:t>
    </w:r>
    <w:r>
      <w:t>
      , ${year}
      </w:t>
    </w:r>
    </w:r>
    `;
}
