import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useMemo } from "react";
import {
  FieldValues,
  FormProvider,
  UseFormProps,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { ZodSchema, z } from "zod";
import { ErrorText } from "./ErrorText.js";
import BooleanInputFormControl from "./hook-form/BooleanInputFormControl.js";
import DateInputFormControl from "./hook-form/DateInputFormControl.js";
import ReactSelectInputFormControl, {
  ReactMultiSelectInputFormControl,
} from "./hook-form/ReactSelectInputFormControl.js";
import SelectInputFormControl from "./hook-form/SelectInputFormControl.js";
import SwitchInputFormControl from "./hook-form/SwitchInputFormControl.js";
import TextAreaInputFormControl from "./hook-form/TextAreaInputFormControl.js";
import TextInputFormControl from "./hook-form/TextInputFormControl.js";
import HiddenInputFormControl from "./hook-form/HiddenInputFormControl.js";

type FormProps<T extends ZodSchema> = {
  children: React.ReactNode;
  onSubmit: (data: T["_output"]) => any;
  serverFieldErrors?: any;
  serverFormErrors?: any[];
  schema: T;
  form: UseFormReturn;
};

const createZodForm = <T extends ZodSchema>(schema: T, form: UseFormReturn) => {
  return {
    Form: (props: Omit<FormProps<T>, "schema" | "form">) => (
      <Form {...props} schema={schema} form={form} />
    ),
    TextInput: TextInputFormControl as typeof TextInputFormControl<T["_input"]>,
    HiddenInput: HiddenInputFormControl as typeof TextInputFormControl<
      T["_input"]
    >,
    SelectInput: SelectInputFormControl as typeof SelectInputFormControl<
      T["_input"]
    >,
    BooleanInput: BooleanInputFormControl as typeof BooleanInputFormControl<
      T["_input"]
    >,
    SwitchInput: SwitchInputFormControl as typeof SwitchInputFormControl<
      T["_input"]
    >,
    TextAreaInput: TextAreaInputFormControl as typeof TextAreaInputFormControl<
      T["_input"]
    >,
    DateInput: DateInputFormControl as typeof DateInputFormControl<T["_input"]>,
    ReactSelect:
      ReactSelectInputFormControl as typeof ReactSelectInputFormControl<
        T["_input"]
      >,
    ReactMultiSelect:
      ReactMultiSelectInputFormControl as typeof ReactMultiSelectInputFormControl<
        T["_input"]
      >,
  };
};

export type UseZodFormProps<T extends FieldValues> = Omit<
  UseFormProps<T>,
  "resolver"
>;

export function useZodForm<T extends ZodSchema>(
  schema: T,
  formProps: UseZodFormProps<z.infer<T>>
) {
  const form = useForm<z.infer<T>, any>({
    ...formProps,
    resolver: zodResolver(schema),
  });

  const zodForm = useMemo(() => createZodForm(schema, form), [schema, form]);

  return {
    form,
    ...zodForm,
  };
}

export function Form<T extends ZodSchema>(props: FormProps<T>) {
  const form = props.form;
  useEffect(() => {
    for (const errorKey in props.serverFieldErrors) {
      const errorMessage = props.serverFieldErrors[errorKey];

      form.setError(errorKey, {
        type: "server",
        message: errorMessage,
      });
    }
  }, [form, props.serverFieldErrors]);

  const rootFormErrors = props.serverFormErrors || [];

  return (
    <FormProvider {...form}>
      {rootFormErrors.map((error: any, i: number) => {
        return <ErrorText key={i}>{error}</ErrorText>;
      })}
      <form onSubmit={form.handleSubmit(props.onSubmit)}>{props.children}</form>
    </FormProvider>
  );
}
