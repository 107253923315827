import { Box, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import TransactionConfirmation from "../forms/TransactionConfirmation.js";
import TransactionForm from "../forms/TransactionForm.js";
import { TxFormSchema } from "../lib/useActionProcessor.js";
import useLocalStorage from "../lib/useLocalStorage.js";
import { useSearchState } from "../lib/useSearchState.js";
import { parseTxCommands } from "../lib/useCommands.js";
import { trpc } from "../utils/trpcClient.js";
import { useCompanyData } from "../lib/useCompanyData.js";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../pages/LoadingPage.js";
import { useUpdatableKey } from "../lib/useUpdatableKey.js";

export function NewTransaction() {
  const [txData, setTxData] = useLocalStorage<TxFormSchema | null>(
    "txData",
    null
  );
  const [showConfirm, setShowConfirm] = useSearchState("confirm", false);
  const [formKey, newFormKey] = useUpdatableKey();
  const { companyId } = useCompanyData();
  const trpcCtx = trpc.useContext();
  const navigate = useNavigate();
  const toast = useToast();
  const [success, setSuccess] = useState(false);

  const executeCommandsMutation = trpc.stocks.executeCommands.useMutation({
    onSuccess() {
      console.log("success");
      setTxData(null);
      newFormKey();
      console.log("cleared data and invalided form: ", txData, formKey);
      toast({
        title: "Transaction executed",
        status: "success",
      });
      trpcCtx.stocks.invalidate();
      setSuccess(true);
    },
    onError(e) {
      toast({
        title: "Error executing transaction",
        description: e.message,
        status: "error",
      });
      console.error(e);
    },
  });

  //TODO: this is neccessary so that we make sure setTxData(null) has time to finish executing correctly
  // because otherwise we might navigate away before the useEffect loop in it triggers
  // Feels wrong, so should look for a better way to do this
  useEffect(() => {
    if (success) {
      navigate("/ledger");
    }
  }, [success, navigate]);

  useEffect(() => {
    if (!success && showConfirm && !txData) {
      setShowConfirm(false);
    }
  }, [success, showConfirm, txData, setShowConfirm]);

  if (executeCommandsMutation.isLoading) {
    return <LoadingPage />;
  }

  return (
    <Box>
      {!showConfirm && (
        <TransactionForm
          key={formKey}
          initialValues={txData || {}}
          onReset={() => {
            setTxData(null);
            newFormKey();
          }}
          onSubmit={async (data) => {
            setTxData(data);
            setShowConfirm(true);
          }}
        />
      )}
      {showConfirm && txData && (
        <TransactionConfirmation
          data={txData}
          onCancel={() => {
            setShowConfirm(false);
          }}
          onConfirm={(data) => {
            console.log("confirming data: ", data);
            const commands = parseTxCommands(companyId, data);
            executeCommandsMutation.mutate({
              companyId,
              commands,
            });
          }}
        />
      )}
    </Box>
  );
}

export default NewTransaction;
