import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from "react-hook-form";
import DatePickerInput, {
  DatePickerInputProps,
} from "../controls/DateInput.js";

type DateInputFormControlProps<T> = {
  name: Path<T>;
  label: string;
} & Omit<DatePickerInputProps, "onBlur" | "value" | "onChange">;

export function DateInputFormControl<T extends FieldValues>(
  props: DateInputFormControlProps<T>
) {
  const formCtx = useFormContext<T>();
  const { name, label, ...datePickerProps } = props;
  formCtx.formState.errors; //TODO... this seems required to get errors to show up???
  const errors = formCtx.getFieldState(props.name).error;
  const { field } = useController({
    name: props.name,
    control: formCtx.control,
  });

  return (
    <DatePickerInput
      {...datePickerProps}
      name={name}
      onChange={field.onChange}
      onBlur={field.onBlur}
      label={label}
      error={errors}
      value={field.value}
    />
  );
}

export default DateInputFormControl;
