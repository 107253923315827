import { ReactSelectInputFormControl } from "@hobson/forms";
import { useMemo } from "react";
import CertificateCell from "../components/CertificateCell.js";
import { useCurrentCompany } from "../utils/useCompany.js";

type Props = {
  shareholderId?: string;
  filterState?: "issued" | "canceled" | "void";
  name: string;
  label: string;
};

const CertificateField = ({
  shareholderId,
  filterState,
  name,
  ...rest
}: Props) => {
  const { company } = useCurrentCompany();
  const certificateOptions = useMemo(() => {
    return company.certificatesList
      .filter((c) => {
        let correctState = true;
        let correctShareholder = true;
        if (filterState) {
          correctState = c.state === filterState;
        }
        if (shareholderId) {
          correctShareholder = c.shareholderId === shareholderId;
        }
        return correctState && correctShareholder;
      })
      .map((cert) => {
        return {
          value: cert.number,
          label: <CertificateCell certificate={cert} />,
        };
      });
  }, [company, filterState, shareholderId]);

  return (
    <ReactSelectInputFormControl
      options={certificateOptions}
      name={name}
      {...rest}
    />
  );
};

export default CertificateField;
