// FIXME this code was copied from old JS app... needs better refactoring to make it better and more readable/typesafe
import { PlainDate, fullName, toPercent } from "@hobson/utils";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { UserOptions } from "jspdf-autotable";
import { useMemo } from "react";
import { Company } from "stocksmith";
import { useCompanyData } from "../../lib/useCompanyData.js";
import ReportViewer from "./ReportViewer.js";
import { ShareholderProfile } from "../../lib/useShareholders.js";

interface jsPDFCustom extends jsPDF {
  autoTable: (options: UserOptions) => void;
}

type Args = {
  shareholders: ShareholderProfile[];
  company: Company;
};

//eslint-disable-next-line react-refresh/only-export-components
export const ownershipReport = async ({ shareholders, company }: Args) => {
  const doc = new jsPDF("portrait", "in", [8.5, 11]) as jsPDFCustom;
  doc.setFileId("ownership-summary");

  let totalPercent = 0;
  let totalShares = 0;
  const body = shareholders.map((s) => {
    totalPercent += s.ownershipSummary?.ownershipPercent || 0;
    totalShares += s.ownershipSummary?.totalShares || 0;
    return [
      fullName(s),
      s.trustee,
      s.ownershipSummary?.totalShares?.toLocaleString(),
      toPercent(s.ownershipSummary?.ownershipPercent),
    ] as string[];
  });

  doc.setFontSize(18);
  doc.text(`Ownership Summary for ${company.name}`, 0.5, 0.5);
  doc.setFontSize(12);
  doc.text(`Generated on ${new Date().toLocaleDateString()}`, 0.5, 0.75);

  doc.autoTable({
    bodyStyles: {
      fontSize: 10,
    },
    startY: 1,
    margin: {
      left: 0.5,
      right: 0.5,
      top: 0.5,
      bottom: 0.5,
    },
    head: [["Name", "Trustee", "#", "%"]],
    body: body,
    foot: [
      ["Total", "", totalShares.toLocaleString(), toPercent(totalPercent, 1)],
    ],
    footStyles: {
      fontSize: 12,
      fillColor: [39, 116, 72],
    },
    headStyles: {
      fontSize: 12,
      fillColor: [39, 116, 72],
    },
    showFoot: "lastPage",
  });

  doc.setProperties({
    title: "Ownership Summary",
  });

  return doc;
};

export function OwnershipSummaryReport() {
  const { shareholders, company } = useCompanyData();

  const filteredShareholders = useMemo(
    () => shareholders.filter((s) => s.isActive),
    [shareholders]
  );
  return (
    <ReportViewer
      generateReport={ownershipReport}
      args={{
        shareholders: filteredShareholders,
        company,
      }}
      filename={`${company.id}_ownership_${new PlainDate().toString()}.pdf`}
    />
  );
}
