import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider, AuthRoutes } from "@hobson/clerk-web-auth";
import { Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./global.css";
import { PageShell } from "./layout/PageShell.js";
import CertificatePage from "./pages/CertificatePage.js";
import CertificatesPage from "./pages/CertificatesPage.js";
import CompanyPage from "./pages/CompanyPage.js";
import ErrorPage from "./pages/ErrorPage.js";
import LedgerPage from "./pages/LedgerPage.js";
import LoadingPage from "./pages/LoadingPage.js";
import { MailingLabelsReportPage } from "./pages/MailingLabelsReportPage.js";
import NotFoundPage from "./pages/NotFoundPage.js";
import ReportsPage from "./pages/ReportsPage.js";
import SettingsPage from "./pages/SettingsPage.js";
import ShareholderPage from "./pages/ShareholderPage.js";
import ShareholdersPage from "./pages/ShareholdersPage.js";
import TransactionsPage from "./pages/TransactionsPage.js";
import UserProfilePage from "./pages/UserProfilePage.js";
import { JournalReport } from "./reports/pdf/journalReport.js";
import { LedgerReport } from "./reports/pdf/ledgerReport.js";
import { OwnershipSummaryReport } from "./reports/pdf/ownershipSummary.js";
import { ProxyReport } from "./reports/pdf/proxy.js";
import { theme } from "./theme.js";
import { TrpcReactProvider } from "./utils/trpcClient.js";

const clerkPublishableKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <PageShell />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <CompanyPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/shareholders",
          element: <ShareholdersPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/shareholders/:shareholderId",
          element: <ShareholderPage />,
          errorElement: <NotFoundPage />,
        },
        {
          path: "/certificates",
          element: <CertificatesPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/certificates/:certificateNumber",
          element: <CertificatePage />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/ledger",
          element: <LedgerPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/transactions",
          element: <TransactionsPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/settings",
          element: <SettingsPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/reports",
          element: <ReportsPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/reports/mailing-labels",
          element: <MailingLabelsReportPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/reports/ownership-summary",
          element: <OwnershipSummaryReport />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/reports/journal",
          element: <JournalReport />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/reports/ledger",
          element: <LedgerReport />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/reports/ledger/:shareholderId",
          element: <LedgerReport />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/reports/ledger/:active",
          element: <LedgerReport />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/reports/proxy",
          element: <ProxyReport />,
          errorElement: <ErrorPage />,
        },
      ],
    },
    ...AuthRoutes,
    {
      path: "/user",
      element: <UserProfilePage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/*",
      element: (
        <PageShell>
          <NotFoundPage />
        </PageShell>
      ),
      errorElement: <ErrorPage />,
    },
  ],
  {}
);

function App() {
  return (
    <ChakraProvider
      theme={theme}
      toastOptions={{
        defaultOptions: {
          position: "top-right",
          isClosable: true,
          duration: 5000,
        },
      }}
    >
      <AuthProvider
        clerkPublishableKey={clerkPublishableKey}
        loader={<LoadingPage />}
      >
        <TrpcReactProvider>
          <Suspense fallback={<LoadingPage />}>
            {/* <LoadingPage /> */}
            <RouterProvider
              router={router}
              fallbackElement={<LoadingPage />}
              future={{ v7_startTransition: true }}
            />
          </Suspense>
        </TrpcReactProvider>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
