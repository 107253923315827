import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import AddCompanyForm from "../forms/AddCompanyForm.js";
import { useGlobalDisclosure } from "../lib/globals.js";
import { useCompanies } from "../utils/useCompany.js";

export function AddCompanyModal() {
  const { addCompanyMutation } = useCompanies();
  const { isOpen, onClose } = useGlobalDisclosure("addCompanyModal");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Company</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          <AddCompanyForm
            onSubmit={async (values) => {
              await addCompanyMutation.mutateAsync(values);
              onClose();
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AddCompanyModal;
