import {
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useAuth } from "@hobson/clerk-web-auth";
import {
  FiBarChart2,
  FiBook,
  FiFileText,
  FiHome,
  FiLogOut,
  FiRepeat,
  FiUsers,
} from "react-icons/fi/index.js";
import SelectCompanyButton from "../components/SelectCompanyButton.js";
import { useCompanies } from "../utils/useCompany.js";
import { Logo } from "./Logo.js";
import { NavButton } from "./NavButton.js";
import { UserProfile } from "./UserProfile.js";

export const Sidebar = ({ fixed }: { fixed?: boolean }) => {
  const { signOut } = useAuth();
  const { currentCompany } = useCompanies();

  return (
    <Flex
      bg="bg-accent"
      color="on-accent"
      overflowY="auto"
      minW={{ base: 0, sm: 300 }}
      py={{ base: "6", sm: "8" }}
      px={{ base: "2", sm: "4" }}
    >
      <Stack justify="space-between" spacing="1" width="full">
        <Stack
          spacing="5"
          shouldWrapChildren
          position={fixed ? "fixed" : "initial"}
        >
          <Logo />
          {currentCompany && <SelectCompanyButton name={currentCompany.name} />}
          <Divider borderColor="bg-accent-subtle" />
          <Stack spacing="1">
            <NavButton label="Company" icon={FiHome} to={"/"} />
            <NavButton
              label="Shareholders"
              icon={FiUsers}
              to={"/shareholders"}
            />
            <NavButton
              label="Certificates"
              icon={FiFileText}
              to={"/certificates"}
            />
            <NavButton label="Ledger / Timeline" to={"/ledger"} icon={FiBook} />
            <NavButton
              label="Transactions"
              icon={FiRepeat}
              to={"/transactions"}
            />
            <NavButton label="Reports" icon={FiBarChart2} to={"/reports"} />
            {/* <NavButton label="Settings" icon={FiSettings} to={"/settings"} /> */}
          </Stack>
          <Divider borderColor="bg-accent-subtle" />
          <Stack spacing="1">
            <UserProfile />
          </Stack>
          <Divider borderColor="bg-accent-subtle" />
          <Stack spacing="1">
            <Button
              variant="ghost-on-accent"
              justifyContent="start"
              onClick={() => signOut()}
            >
              <HStack spacing="3">
                <Icon as={FiLogOut} boxSize="6" color="on-accent-subtle" />
                <Text>Logout</Text>
              </HStack>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
};
