import { Text } from "@chakra-ui/react";

type Props = {
  certificate: {
    number: number;
    shareholder?: {
      name: string;
      trustee: string;
    };
    shareholderId: string;
    shares: number;
    state: string;
  };
};
const CertificateCell = ({ certificate }: Props) => {
  return (
    <Text>{`#${
      certificate.number
    } ${certificate.shares.toLocaleString()} Shares`}</Text>
  );
};

export default CertificateCell;
