import { Box, Button, FormControl, Stack, Text } from "@chakra-ui/react";
import { z } from "zod";

import { useZodForm } from "../createForm.js";
import { randomNames } from "../randomNames.js";

const shirtSizes = ["", "xs", "s", "m", "l", "xl"] as const;
const shirtSizeOptions = shirtSizes.map((size) => ({
  label: size === "" ? "Select Size" : size.toUpperCase(),
  value: size,
}));

const formSchema = z.object({
  firstName: z.string().min(2).max(20),
  lastName: z.string().max(20).optional(),
  email: z.string(),
  birthDate: z.date().optional(),
  height: z.coerce.number().min(18),
  enabled: z.boolean(),
  notes: z.string().min(0).max(1000),
  pref1: z.boolean(),
  pref2: z.boolean(),
  pref3: z.boolean(),
  friend: z.string().optional(),
  shirtSize: z.preprocess(
    (v) => (v === "" ? undefined : v),
    z.enum(shirtSizes).optional()
  ),
  friends: z.array(z.string()),
  nested: z.object({
    value: z.string(),
  }),
});

const friendOptions = randomNames.map((name) => ({
  label: name,
  value: name,
}));

type BasicFormExProps = {
  onSubmit: (values: z.infer<typeof formSchema>) => void;
};

export function BasicFormEx(props: BasicFormExProps) {
  const zf = useZodForm(formSchema, {
    defaultValues: {},
  });

  return (
    <Box margin="auto">
      <Stack>
        <Text>Basic Form Example</Text>

        <zf.Form onSubmit={props.onSubmit}>
          <Stack>
            <zf.ReactSelect
              name="friend"
              label="Friend"
              options={friendOptions}
            />
            <zf.ReactMultiSelect
              name="friends"
              label="Friend"
              options={friendOptions}
            />

            <zf.TextInput name="firstName" label="First Name" />
            <zf.TextInput name="lastName" label="Last Name" />
            <zf.TextInput name="email" label="Email" />
            <zf.DateInput name="birthDate" label="Birth Date" clearable />
            <zf.TextInput
              name="height"
              label="Height"
              helpText="Height in inches"
            />
            <zf.TextAreaInput name="notes" label="Notes" />
            <zf.BooleanInput name="enabled" label="Enabeled" />
            <zf.SelectInput
              name="shirtSize"
              label="Shirt Size"
              options={shirtSizeOptions}
            />
            <zf.TextInput name="nested.value" label="Nested Value" />
            <Stack spacing={3} pt={4} pb={4}>
              <zf.SwitchInput name="pref1" label="Preference 1" />
              <zf.SwitchInput name="pref2" label="Preference 2" />
              <zf.SwitchInput name="pref3" label="Preference 3" />
            </Stack>
            <FormControl>
              <Button type="submit">Submit</Button>
            </FormControl>
          </Stack>
        </zf.Form>
      </Stack>
    </Box>
  );
}

export default BasicFormEx;
