import { Box, Button, Flex, HStack, Text } from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi/index.js";
import { useSearchParams } from "react-router-dom";
import { Link } from "../components/Link.js";
import ShareholderList from "../components/ShareholderList.js";
import { useShareholderModal } from "../lib/useShareholderModal.js";

export function ShareholdersPage() {
  const { addShareholder } = useShareholderModal();
  const [params] = useSearchParams();

  const filter = params.get("filter") || "active";

  return (
    <Box>
      <Box mb="4">
        <HStack spacing="10" justifyContent="center">
          <Link
            to="?filter=active"
            fontSize="xl"
            isActive={filter === "active"}
          >
            Active
          </Link>
          <Link
            to="?filter=inactive"
            fontSize="xl"
            isActive={filter === "inactive"}
          >
            Inactive
          </Link>
          <Link to="?filter=all" fontSize="xl" isActive={filter === "all"}>
            All
          </Link>
        </HStack>
      </Box>
      <Flex justifyContent="flex-start" mb="2">
        <Button colorScheme="green" onClick={addShareholder}>
          <FiPlus color="white" size={22} />
          <Text ml="2">Add Shareholder</Text>
        </Button>
      </Flex>

      <ShareholderList filter={filter} />
    </Box>
  );
}

export default ShareholdersPage;
