import { Button, Stack, Box, Text, TableContainer } from "@chakra-ui/react";
import { useShareholders } from "../lib/useShareholders.js";
import { createCertificatesReport } from "../reports/excel/certificateXLSXReport.js";
import { useCurrentCompany } from "../utils/useCompany.js";
import { CertificatesTable } from "./CertificatesTable.js";
import { FiDownload } from "react-icons/fi/index.js";

type Props = {
  filter: string;
};

function CertificatesList(props: Props) {
  const { company } = useCurrentCompany();
  const { profilesById } = useShareholders();

  const certList = (company.certificatesList || []).filter((c) => {
    if (props.filter === "all") return true;
    return c.state === props.filter;
  });
  const certificatesWithShareholders = certList.map((certificate) => {
    const shareholder = profilesById[certificate.shareholderId];
    return {
      certificate,
      shareholder,
    };
  });

  return (
    <Stack>
      <Box>
        <Button
          onClick={() => {
            createCertificatesReport(certificatesWithShareholders);
          }}
        >
          <FiDownload size={22} />
          <Text ml="2">Download as Excel</Text>
        </Button>
      </Box>
      <TableContainer>
        <CertificatesTable
          size="sm"
          width="100%"
          data={certificatesWithShareholders}
          viewLinkPath={(id) => {
            return `/certificates/${id}`;
          }}
        />
      </TableContainer>
    </Stack>
  );
}

export default CertificatesList;
