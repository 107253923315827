import { Button, Container, Heading, HStack, Stack } from "@chakra-ui/react";
import { useZodForm } from "@hobson/forms";
import { z } from "zod";

const shareholderSchema = z.object({
  folioId: z.string().max(32).optional(),
  email: z.string().email().optional().or(z.literal("")),
  firstName: z.string().optional(),
  middleName: z.string().optional(),
  lastName: z.string().optional(),
  trustee: z.string().optional(),
  taxId: z.string().optional(),
  addressLine1: z.string().optional(),
  addressLine2: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
  homePhone: z.string().optional(),
  workPhone: z.string().optional(),
  homeFax: z.string().optional(),
  workFax: z.string().optional(),
  // dateCeased: z.date().optional(),
});
type FormSchema = z.infer<typeof shareholderSchema>;

type ShareholderFormProps = {
  shareholderId?: string;
  onSubmit: (data: FormSchema) => void;
  initialData?: Partial<FormSchema>;
  submitText?: string;
};

export function ShareholderForm(props: ShareholderFormProps) {
  const zf = useZodForm(shareholderSchema, {
    defaultValues: props.initialData,
  });

  return (
    <zf.Form onSubmit={props.onSubmit}>
      <Container>
        <Stack gap="2" mb="4">
          <Stack>
            {/* <pre>{JSON.stringify(zf.form.formState.errors, null, 2)}</pre> */}
            <Heading fontSize="2xl" color="gray.600">
              Basic Info
            </Heading>
            <HStack>
              <zf.TextInput name="firstName" label="First Name" />
              <zf.TextInput name="middleName" label="Middle Name" />
              <zf.TextInput name="lastName" label="Last or Entity Name" />
            </HStack>
            <zf.TextInput
              name="trustee"
              label="Trustee or For the Benefit of"
            />
            <zf.TextInput name="taxId" label="SSN or EIN" />

            <zf.TextInput
              name="folioId"
              label="Folio ID #"
              helpText="Legacy Folio ID number(s) if applicable"
            />
            {/* <zf.DateInput name="dateCeased" label="Date Ceased" /> */}
          </Stack>
          <Stack mt="4">
            <Heading fontSize="2xl" color="gray.600">
              Contact Info
            </Heading>
            <zf.TextInput name="email" label="Email" />
            <HStack>
              <zf.TextInput name="homePhone" label="Home Phone" />
              <zf.TextInput name="workPhone" label="Work Phone" />
            </HStack>
            <HStack>
              <zf.TextInput name="homeFax" label="Home Fax" />
              <zf.TextInput name="workFax" label="Work Fax" />
            </HStack>
          </Stack>
          <Stack mt="4">
            <Heading fontSize="2xl" color="gray.600">
              Address
            </Heading>
            <zf.TextInput name="addressLine1" label="Address 1" />
            <zf.TextInput name="addressLine2" label="Address 2" />
            <HStack>
              <zf.TextInput name="city" label="City" />
              <zf.TextInput name="state" label="State" />
              <zf.TextInput name="zip" label="Zip" />
            </HStack>
          </Stack>
          <Button type="submit" mt="4" colorScheme="green">
            {props.submitText || "Submit"}
          </Button>
        </Stack>
      </Container>
    </zf.Form>
  );
}

export default ShareholderForm;
