import {
  HStack,
  IconButton,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpoint,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FiEdit2, FiEye, FiTrash2 } from "react-icons/fi/index.js";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "./Link.js";
import { RouterOutput } from "../utils/trpcClient.js";
import Address from "./Address.js";
import { ShareholderWithCompanyStats } from "stocksmith";
import { ShareholderNameCell } from "./ShareholderNameCell.js";
import { toPercent } from "@hobson/utils";

type Shareholder = RouterOutput["shareholders"]["list"][0];

type ShareholderTableProps = TableProps & {
  shareholders: ShareholderWithCompanyStats<Shareholder>[];
  onEditShareholder: (id: string) => void;
  onDeleteShareholder: (id: string) => void;
  viewLinkPath: (id: string) => string;
};

export const ShareholdersTable = ({
  shareholders,
  onEditShareholder,
  onDeleteShareholder,
  viewLinkPath,
  ...tableProps
}: ShareholderTableProps) => {
  const showEmail = useBreakpointValue(
    { base: false, sm: false, md: true },
    { fallback: "base" }
  );
  const splitEmail = useBreakpointValue(
    { base: true, xl: false },
    { fallback: "base" }
  );
  return (
    <Table {...tableProps}>
      <Thead>
        <Tr>
          <Th>Name</Th>
          {showEmail && <Th>Email</Th>}
          <Th>Address</Th>
          <Th>Tax ID</Th>
          <Th># Shares</Th>
          <Th>%</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {shareholders.map((shareholder) => {
          const emailDisplay = splitEmail
            ? (shareholder?.email || "").split("@").join("\n@")
            : shareholder?.email || "";
          const taxIdFormmated = shareholder.taxIdLast4
            ? `***-**-${shareholder.taxIdLast4}`
            : "";
          const toShareholder = viewLinkPath(shareholder.id);
          return (
            <Tr key={shareholder.id}>
              <Td>
                <ShareholderNameCell shareholder={shareholder} />
              </Td>
              {showEmail && (
                <Td
                  minWidth={{ base: 30 }}
                  maxWidth={{ base: 40, xl: 60 }}
                  whiteSpace={splitEmail ? "pre" : "nowrap"}
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  <Link to={`mailto:${shareholder.email}`} reloadDocument>
                    {emailDisplay}
                  </Link>
                </Td>
              )}
              <Td>
                <Address
                  address={{
                    address1: shareholder.addressLine1,
                    address2: shareholder.addressLine2,
                    city: shareholder.city,
                    state: shareholder.state,
                    zip: shareholder.zip,
                  }}
                />
              </Td>
              <Td>{taxIdFormmated}</Td>
              <Td>{shareholder?.ownershipSummary?.totalShares || 0}</Td>
              <Td>
                {toPercent(shareholder?.ownershipSummary?.ownershipPercent)}
              </Td>
              <Td>
                <HStack spacing="1">
                  <IconButton
                    icon={<FiEye fontSize="1.25rem" />}
                    variant="tertiary"
                    aria-label="Vew shareholder"
                    as={RouterLink}
                    to={toShareholder}
                  />
                  <IconButton
                    icon={<FiEdit2 fontSize="1.25rem" />}
                    variant="tertiary"
                    aria-label="Edit shareholder"
                    onClick={() => onEditShareholder(shareholder.id)}
                  />

                  <IconButton
                    icon={<FiTrash2 fontSize="1.25rem" />}
                    variant="tertiary"
                    aria-label="Delete shareholder"
                    color="red.500"
                    onClick={() => onDeleteShareholder(shareholder.id)}
                  />
                </HStack>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
