import {
  Box,
  Button,
  Heading,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  UnorderedList,
} from "@chakra-ui/react";
import { useZodForm } from "@hobson/forms";
import { fullName } from "@hobson/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { Link } from "../components/Link.js";
import { useCompanyData } from "../lib/useCompanyData.js";

export function ReportsPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParmas] = useSearchParams();
  const showModal = searchParams.get("showModal");
  const isOpen = showModal === "true";

  const onClose = () => {
    setSearchParmas({});
  };

  return (
    <Box pl="2">
      <Stack>
        <Heading size="lg" color="green.500">
          PDF Reports
        </Heading>
        <UnorderedList fontSize="xl">
          <ListItem>
            <Link to="/reports/journal">Journal</Link>
          </ListItem>
          <ListItem>
            <Link to="/reports/ownership-summary">Ownership Summary</Link>
          </ListItem>
          <ListItem>
            <Link to="/reports?showModal=true">Single Shareholder Ledger</Link>
          </ListItem>
          <ListItem>
            <Link to="/reports/ledger/active">
              All Active Shareholder Ledgers
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/reports/ledger">All Shareholder Ledgers</Link>
          </ListItem>
          <ListItem>
            <Link to="/reports/mailing-labels">Mailing Labels</Link>
          </ListItem>
          <ListItem>
            <Link to="/reports/proxy">Proxy</Link>
          </ListItem>
        </UnorderedList>
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Shareholder</ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody>
            <SelectShareholderForm
              onSubmit={(data) => {
                navigate(`/reports/ledger/${data.id}`);
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

const schema = z.object({
  id: z.string(),
});

type Props = {
  onSubmit: (d: z.infer<typeof schema>) => void;
};
const SelectShareholderForm = ({ onSubmit }: Props) => {
  const { shareholders } = useCompanyData();

  const shareholderOptions = shareholders
    .map((s) => {
      if (s.isActive) {
        const label = [fullName(s), s.trustee].filter(Boolean).join(", ");
        return {
          label: `${label}`,
          value: s.id,
        };
      } else {
        return null;
      }
    })
    .filter(Boolean);
  const zf = useZodForm(schema, {});

  return (
    <zf.Form onSubmit={onSubmit}>
      <Stack spacing="6">
        <zf.ReactSelect
          name="id"
          label="Shareholder"
          options={shareholderOptions}
        />
        <Button type="submit">Select</Button>
      </Stack>
    </zf.Form>
  );
};

export default ReportsPage;
