import { Box, Button, Flex, Stack } from "@chakra-ui/react";
import { useZodForm } from "@hobson/forms";
import { fullName } from "@hobson/utils";
import { useCallback, useEffect, useState } from "react";
import { useCompanyData } from "../lib/useCompanyData.js";
import { generatePdfReport } from "../reports/pdf/index.js";
import {
  LabelData,
  MailingLabelsParams,
  defaultMailingLabels,
  mailingLabelsReport,
  mailingLabelsSchema,
} from "../reports/pdf/mailingLabels.js";

const alignOptions = [
  { value: "left", label: "Left" },
  { value: "center", label: "Center" },
];

export const MailingLabelsReportPage = () => {
  const zf = useZodForm(mailingLabelsSchema, {
    defaultValues: defaultMailingLabels,
  });

  const { shareholders } = useCompanyData();

  const [reportData, setReportData] = useState<string | null>(null);
  const generateReport = useCallback(
    async (params: MailingLabelsParams) => {
      const labelData: LabelData[] = [];

      for (const shareholder of shareholders) {
        if (shareholder.isActive) {
          labelData.push({
            line1: fullName(shareholder),
            line2: shareholder.trustee,
            line3: `${shareholder.addressLine1} ${shareholder.addressLine2}`,
            line4: `${shareholder.city}, ${shareholder.state} ${shareholder.zip}`,
          } as LabelData);
        }
      }

      const result = await generatePdfReport(mailingLabelsReport, {
        labels: labelData,
        params,
      });
      setReportData(result);
    },
    [shareholders]
  );

  useEffect(() => {
    if (!reportData) {
      generateReport(defaultMailingLabels);
    }
  }, [generateReport, reportData]);

  console.log("rendering report: ", reportData);

  return (
    <Flex>
      <Box width="240px" pr="10">
        <zf.Form
          onSubmit={(data) => {
            generateReport(data);
          }}
        >
          <Stack>
            <zf.TextInput name="pageWidth" label="Page Width (inches)" />
            <zf.TextInput name="pageHeight" label="Page Height (inches)" />
            <zf.TextInput name="topMargin" label="Top Margin (1/72 in)" />
            <zf.TextInput
              name="bottomMargin"
              label="Bottom Margin  (1/72 in)"
            />
            <zf.TextInput name="columns" label="Columns" />
            <zf.TextInput name="rows" label="Rows" />
            <zf.TextInput name="fontSize" label="Font Size" />
            <zf.TextInput name="leftMargin" label="Left Margin  (1/72 in)" />
            <zf.TextInput name="rightMargin" label="Right Margin  (1/72 in)" />
            <zf.TextInput name="columnGutter" label="Column Gutter (1/72 in)" />
            <zf.TextInput name="rowGutter" label="Row Gutter (1/72 in)" />
            <zf.SelectInput
              name="align"
              options={alignOptions}
              label="Text Align"
            />
            <zf.BooleanInput name="showBorder" label="Draw Label Border" />
            <Button colorScheme="green" type="submit" mt="2">
              Generate Labels
            </Button>
            <Button
              colorScheme="gray"
              href={reportData || "#"}
              as="a"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open PDF in new Page
            </Button>
          </Stack>
        </zf.Form>
      </Box>
      <Box bg="gray.100" flex={1} height="calc(100vh - 70px)">
        <iframe
          key={reportData || ""}
          src={reportData || ""}
          width="100%"
          height="100%"
          style={{ flex: 1 }}
        />
      </Box>
    </Flex>
  );
};
