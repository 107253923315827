import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Stack,
  Divider,
} from "@chakra-ui/react";
import React from "react";
import CompanyStats from "../components/CompanyStats.js";
import { Stat } from "../components/Stat.js";
import { TxFormSchema } from "../lib/useActionProcessor.js";
import { useCommands, usePreview } from "../lib/useCommands.js";
import { useCompanyData } from "../lib/useCompanyData.js";
import { EventData } from "../pages/LedgerPage.js";

type TransactionConfirmationProps = {
  data: TxFormSchema;
  onCancel: (e?: any) => void;
  onConfirm: (data: TxFormSchema) => void;
};

export function TransactionConfirmation({
  data,
  onCancel,
  onConfirm,
}: TransactionConfirmationProps) {
  const { profilesById, company } = useCompanyData();

  const certsIssued = data.certificatesToIssue || [];
  const certsCanceled = data.certificatesToBuyBack || [];

  const sharesIssued = certsIssued.reduce((acc, curr) => acc + curr.shares, 0);
  const sharesCanceled = certsCanceled.reduce((acc, curr) => {
    const shares = company?.certificates?.[curr.number]?.shares || 0;
    return acc + shares;
  }, 0);

  const showTreasuryWarning = sharesIssued !== sharesCanceled;
  const commands = useCommands(data);
  const { previewCompany, newEvents } = usePreview(commands);

  const outstandingShareDiff =
    previewCompany?.outstandingShares - company?.outstandingShares;
  const treasuryShareDiff =
    previewCompany?.treasuryShares - company?.treasuryShares;
  const activeCertDiff =
    previewCompany?.getCertificates("issued").length -
    company?.getCertificates("issued").length;

  return (
    <Stack>
      <Box>
        <Button onClick={onCancel}>← Cancel / Edit Transaction</Button>
      </Box>
      <Heading mt={4}>Confirm Changes</Heading>
      <Heading size="lg" mt={2}>
        Transaction Date: {data.date.toString()}
      </Heading>

      <Heading size="lg" mt="4">
        Changes resulting from this transaction
      </Heading>
      {newEvents?.map((event: any, i) => {
        return (
          <React.Fragment key={`event-${i}`}>
            {i !== 0 ? <Divider /> : null}
            <Box pt="2">
              <EventData
                event={event}
                profilesById={profilesById}
                company={previewCompany}
              />
            </Box>
          </React.Fragment>
        );
      })}

      <Heading size="lg" mt={4}>
        Company Details
      </Heading>
      <Box mt={2}>
        <Accordion allowMultiple defaultIndex={[2]}>
          <AccordionItem>
            <AccordionButton>
              <Heading size="md" width="100%">
                <Flex justifyContent="flex-start" columnGap={2}>
                  <AccordionIcon />
                  Before Transaction
                </Flex>
              </Heading>
            </AccordionButton>
            <AccordionPanel pb={4}>
              <CompanyStats company={company} />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Heading size="md" width="100%">
                <Flex justifyContent="flex-start" columnGap={2}>
                  <AccordionIcon />
                  After Transaction
                </Flex>
              </Heading>
            </AccordionButton>
            <AccordionPanel pb={4}>
              <CompanyStats company={previewCompany} />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Heading size="md" width="100%">
                <Flex justifyContent="flex-start" columnGap={2}>
                  <AccordionIcon />
                  Change in Stats
                </Flex>
              </Heading>
            </AccordionButton>
            <AccordionPanel pb={4}>
              {showTreasuryWarning && (
                <Heading size="md" color="orange.500" mt="4">
                  Warning: Confirming this transaction changes the current
                  number of outstanding shares.
                </Heading>
              )}
              <HStack mt="4">
                <Stat
                  label="𝚫 outstanding shares"
                  value={outstandingShareDiff}
                />
                <Stat label="𝚫 treasury shares" value={treasuryShareDiff} />
                <Stat label="𝚫 issued certificates" value={activeCertDiff} />
              </HStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>

      <Button
        colorScheme="green"
        size="lg"
        onClick={() => {
          onConfirm(data);
        }}
      >
        CONFIRM
      </Button>
    </Stack>
  );
}

export default TransactionConfirmation;
