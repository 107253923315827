import { Heading, HeadingProps, HStack } from "@chakra-ui/react";
import { AiOutlineStock } from "react-icons/ai/index.js";
import { Link } from "react-router-dom";

export const Logo = (props: HeadingProps) => {
  return (
    <Link to="/">
      <HStack>
        <AiOutlineStock size={48} />
        <Heading {...props}>Stocksmith</Heading>
      </HStack>
    </Link>
  );
};
