import { useCallback } from "react";
import { trpc } from "../utils/trpcClient.js";
import { useCurrentCompany } from "../utils/useCompany.js";

export function useCertificateConfig() {
  const { companyId } = useCurrentCompany();
  const configQuery = trpc.certificateSettings.get.useQuery(
    { companyId },
    {
      suspense: true,
    }
  );

  const config = configQuery.data;

  const mutation = trpc.certificateSettings.set.useMutation({
    onSuccess() {
      configQuery.refetch();
    },
    onError(error: any) {
      console.error(error);
    },
  });
  const saveLabels = useCallback(
    (updatedObjects: any) => {
      console.log("saving: ", updatedObjects);
      const labelObjects = { ...config?.labelObjects };
      const keys = Object.keys(updatedObjects);
      keys.map((key: any) => {
        const obj = updatedObjects[key];
        labelObjects[key] = {
          ...labelObjects[key],
          x: obj.left,
          y: obj.top,
          // width: obj.width,
          // height: obj.height,
        };
      });

      console.log("saving: ", labelObjects);

      return mutation.mutate({
        companyId,
        labelObjects,
      });
    },
    [companyId, config?.labelObjects, mutation]
  );

  return { config, saveLabels };
}
