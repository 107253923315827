import { Grid } from "@chakra-ui/react";
import { Company } from "stocksmith";
import { Stat } from "./Stat.js";

type CompanyStatsProps = {
  company: Company;
};

export function CompanyStats({ company }: CompanyStatsProps) {
  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6} p={4}>
      <Stat
        label="Authorized Shares"
        value={company?.authorizedShares}
        helper="Maximum number of shares company is allowed to issue."
      />
      <Stat
        label="Issued Shares"
        value={company?.issuedShares}
        helper="total shares that the company is authorized to sell."
      />

      <Stat
        label="Unissued Shares"
        value={company?.authorizedShares - company?.issuedShares}
        helper="Shares that have been authorized but not yet issued for sale"
      />
      <Stat
        label="Treasury Shares"
        value={company?.treasuryShares}
        helper="Issued Shares that have been sold and then bought back by the company"
      />
      <Stat
        label="Outstanding Shares"
        value={company?.outstandingShares}
        helper="Total number of shares that have currently are issued or sold."
      />
      <Stat
        label="Next Certificate Number"
        value={company?.nextCertificateNumber}
        helper="The next certificate number scheduled to be issued."
      />
    </Grid>
  );
}

export default CompanyStats;
