import { PlainDate } from "@hobson/utils";
import { AuthorizedSharesChangedEvent } from "../events/AuthorizedSharesChangedEvent.js";
import { CertificateCanceledEvent } from "../events/CertificateCanceledEvent.js";
import { CertificateIssuedEvent } from "../events/CertificateIssuedEvent.js";
import { CertificateVoidedEvent } from "../events/CertificateVoidedEvent.js";
import { CompanyCreatedEvent } from "../events/CompanyCreatedEvent.js";
import { SetIssuedSharesEvent } from "../events/SetIssuedSharesEvent.js";
import { Event } from "../types.js";

export type CertificateState = "issued" | "canceled" | "void";
type CertificateConstructorProps = {
  number: number;
  shares: number;
  issuedAt: PlainDate;
  shareholderId?: string;
  voidedAt?: PlainDate;
  voidedReason?: string;
};

export class Certificate {
  number: number;
  shares: number;
  shareholderId: string;
  issuedAt: PlainDate;
  canceledAt?: PlainDate;
  voidedAt?: PlainDate;
  canceledReason?: string;
  voidedReason?: string;

  constructor(props: CertificateConstructorProps) {
    this.number = props.number;
    this.shares = props.shares;
    this.issuedAt = props.issuedAt;
    this.voidedAt = props.voidedAt;
    this.voidedReason = props.voidedReason;
    this.shareholderId = props.shareholderId || "VOID";
  }

  get state() {
    if (this.voidedAt) {
      return "void";
    }

    if (this.canceledAt) {
      return "canceled";
    }

    return "issued";
  }
}

export class Shareholder {
  id: string;
  certificates: Certificate[];
  companyOutstandingShares: number;

  constructor(
    id: string,
    certificates: Certificate[],
    companyOutstandingShares: number
  ) {
    this.id = id;
    this.certificates = certificates;
    this.companyOutstandingShares = companyOutstandingShares;
  }

  get shares() {
    return this.certificates.reduce((sum, cert) => {
      if (cert.state === "issued") {
        return sum + cert.shares;
      }
      return sum;
    }, 0);
  }

  getCertificates(state: CertificateState = "issued") {
    return this.certificates.filter((cert) => cert.state === state);
  }

  get ownership() {
    return this.shares / this.companyOutstandingShares;
  }
}

export class Company {
  events: Event[];

  id = "";
  name = "";
  authorizedShares = 0;
  issuedShares = 0;
  startingCertficateNumber = 0;
  certificates: Record<string, Certificate> = {};
  certificateIdsByShareholder: Record<string, number[]> = {};
  shareholderIds: Set<string> = new Set();
  lastEventTimestamp = -1;
  dateEstablished: PlainDate | null = null;

  constructor(events: Event[] = []) {
    this.events = events;
    this.applyEvents(this.events);
  }

  clone() {
    return new Company([...this.events]);
  }

  get outstandingShares() {
    //loop over certificates and sum up the shares
    return Object.keys(this.certificates).reduce((sum, key) => {
      const certificate = this.certificates[key];
      if (certificate.state === "issued") {
        return sum + certificate.shares;
      }
      return sum;
    }, 0);
  }

  get certificatesList() {
    return (
      Object.keys(this.certificates).map((key) => this.certificates[key]) || []
    );
  }

  get treasuryShares() {
    return this.issuedShares - this.outstandingShares;
  }

  getShareholder(id: string) {
    const certificates = this.getCertificatesForShareholder(id);
    return new Shareholder(id, certificates, this.outstandingShares);
  }

  getShareholders() {
    return Array.from(this.shareholderIds).map((id) => this.getShareholder(id));
  }

  getCertificates(state: CertificateState = "issued") {
    return this.certificatesList.filter((cert) => cert.state === state);
  }

  get nextCertificateNumber() {
    const certs = this.certificatesList;
    if (certs.length === 0) {
      return this.startingCertficateNumber;
    }
    const currentMaxNum = this.certificatesList.reduce((max, cert) => {
      if (cert.number > max) {
        return cert.number;
      }
      return max;
    }, this.startingCertficateNumber);
    return currentMaxNum + 1;
  }

  getCertificatesForShareholder(id: string) {
    const certNums = this.certificateIdsByShareholder[id] || [];
    return certNums.map((num) => this.certificates[num]).filter(Boolean);
  }

  private applyEvents(events: Event[]) {
    events.forEach((event) => this.apply(event));
  }

  addEvents(events: Event[]) {
    for (const event of events) {
      this.apply(event);
      this.events.push(event);
    }
  }

  private apply(event: Event) {
    if (event.type === "CompanyCreated") {
      const e = event as CompanyCreatedEvent;
      this.id = e.payload.companyId;
      this.name = e.payload.name;
      this.authorizedShares = e.payload.authorizedShares;
      this.issuedShares = e.payload.issuedShares;
      this.startingCertficateNumber = e.payload.startingCertificateNumber;
      this.dateEstablished = e.payload.date;
      return;
    }

    if (!this.id) {
      throw new Error(
        "Company must be created before additional events can be applied"
      );
    }

    if (event.companyId !== this.id) {
      console.warn(
        `Event companyId ${event.companyId} does not match Company id ${this.id}. Ignoring event.`
      );
      return;
    }

    if (event.timestamp < this.lastEventTimestamp) {
      throw new Error(
        `Trying to apply out-of-order event. Timestamp ${event.timestamp} is less than last event timestamp ${this.lastEventTimestamp}`
      );
    }

    if (event.type === "CertificateIssued") {
      const e = event as CertificateIssuedEvent;
      if (this.certificates[e.payload.certificateNumber]) {
        throw new Error(
          `Certificate number ${e.payload.certificateNumber} already exists`
        );
      }

      this.shareholderIds.add(e.payload.shareholderId);
      this.certificates[e.payload.certificateNumber] = new Certificate({
        number: e.payload.certificateNumber,
        shares: e.payload.shares,
        shareholderId: e.payload.shareholderId,
        issuedAt: e.payload.date,
      });
      this.certificateIdsByShareholder[e.payload.shareholderId] = [
        ...(this.certificateIdsByShareholder[e.payload.shareholderId] || []),
        e.payload.certificateNumber,
      ];
    } else if (event.type === "CertificateCanceled") {
      const e = event as CertificateCanceledEvent;

      if (this.certificates[e.payload.certificateNumber].state !== "issued") {
        throw new Error(
          `Certificate number ${e.payload.certificateNumber} can not be canceled since it is not issued`
        );
      }
      this.certificates[e.payload.certificateNumber].canceledAt =
        e.payload.date;
    } else if (event.type === "CertificateVoided") {
      const e = event as CertificateVoidedEvent;
      if (this.certificates[e.payload.number]) {
        throw new Error(
          `Tried to void an already existing certificate number ${e.payload.number}`
        );
      }
      this.certificates[e.payload.number] = new Certificate({
        number: e.payload.number,
        shares: 0,
        issuedAt: e.payload.date,
        voidedAt: e.payload.date,
        voidedReason: e.payload.note,
      });
    } else if (event.type === "AuthorizedSharesChanged") {
      const e = event as AuthorizedSharesChangedEvent;
      this.authorizedShares = e.payload.authorizedShares;
    } else if (event.type === "SetIssuedShares") {
      const e = event as SetIssuedSharesEvent;
      this.issuedShares = e.payload.number;
    } else {
      throw new Error(`Unknown event type ${event.type}`);
    }
  }
}
