import { Button, Stack } from "@chakra-ui/react";
import { useZodForm } from "@hobson/forms";
import { companyProfileSchema } from "../lib/schemas.js";
import { z } from "zod";

type FormValues = z.infer<typeof companyProfileSchema>;

type CompanyProfileFormProps = {
  defaultValues?: Partial<FormValues>;
  onSubmit: (data: FormValues) => void;
};

const typeOptions = [
  { label: "LLC", value: "LLC" },
  { label: "C-Corp", value: "C-Corp" },
  { label: "S-Corp", value: "S-Corp" },
];

export function CompanyProfileForm(props: CompanyProfileFormProps) {
  const zf = useZodForm(companyProfileSchema, {
    defaultValues: props.defaultValues,
  });

  return (
    <zf.Form onSubmit={props.onSubmit}>
      <Stack>
        <zf.TextInput name="name" label="Company Name" />
        <zf.TextInput
          name="incorporationState"
          label="State of Incorporation"
        />
        <zf.SelectInput name="type" label="Type" options={typeOptions} />
        <zf.TextInput name="addressLine1" label="Address Line 1" />
        <zf.TextInput name="addressLine2" label="Address Line 2" />
        <zf.TextInput name="city" label="City" />
        <zf.TextInput name="state" label="State" />
        <zf.TextInput name="zip" label="Zip" />
        <Button type="submit" colorScheme="green" my={4}>
          Submit
        </Button>
      </Stack>
    </zf.Form>
  );
}

export default CompanyProfileForm;
