// FIXME this code was copied from old JS app... needs better refactoring to make it better and more readable/typesafe
import { fullName } from "@hobson/utils";
import { format } from "date-fns";
import converter from "number-to-words";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
window.format = format;

const getValue = (cert: any, shareholder: any, configKey: any) => {
  const certDate = cert.issuedAt.toDate();
  window.issuedAt = cert.issuedAt;
  window.certDate = cert.issuedAt.toDate();
  console.log("cert date: ", certDate);
  switch (configKey) {
    case "certNum":
      return cert.number.toString();
    case "issuedToName":
      return fullName(shareholder);
    case "issuedToTrustee":
      return shareholder?.trustee;
    case "certSharesText":
      return converter.toWords(cert.shares);
    case "dayInWords":
      return converter.toOrdinal(format(certDate, "d"));
    case "certMonth":
      return format(certDate, "MMMM");
    case "certYear":
      return format(certDate, "yyyy");
    case "receiptShares":
      return cert.shares.toLocaleString();
    case "date":
      return format(certDate, "MMMM d");
    case "year":
      return format(certDate, "yyyy");
    case "certName":
      if (shareholder?.trustee) {
        return `${fullName(shareholder)}\n${shareholder?.trustee}`;
      } else {
        return fullName(shareholder);
      }
    case "certShares":
      return cert.shares.toLocaleString();
    default:
      return configKey.placeholder;
  }
};

const imageUrl =
  "https://minio.rhsdev.com/stocksmith/public/certificate-salinas.jpg";

type Args = {
  labelObjects: any;
  certificate: any;
  shareholder: any;
  showImage?: boolean;
};

const debug = false;
export const certificateReport = async ({
  labelObjects,
  certificate,
  shareholder,
  showImage,
}: Args) => {
  const doc = await PDFDocument.create();

  if (!labelObjects || !certificate) {
    return doc;
  }

  // Embed the Times Roman font
  const timesRomanFont = await doc.embedFont(StandardFonts.TimesRoman);

  const pageW = 792;
  const pageH = 612;
  const page = doc.addPage([pageW, pageH]);

  if (showImage) {
    const image = await fetch(imageUrl);
    const imageBytes = await image.arrayBuffer();

    const jpgImage = await doc.embedJpg(imageBytes);
    page.drawImage(jpgImage, {
      x: 0,
      y: 0,
      width: 792,
      height: 612,
    });
  }

  if (debug) {
    page.drawRectangle({
      x: 0,
      y: 0,
      width: 50,
      height: 50,
      color: rgb(1, 0, 0),
    });

    page.drawRectangle({
      x: 792 - 50,
      y: 612 - 50,
      width: 50,
      height: 50,
      color: rgb(0, 1, 0),
    });
  }

  const keys = Object.keys(labelObjects);

  const colors = [
    rgb(1, 0, 0),
    rgb(0, 1, 0),
    rgb(0, 0, 1),
    rgb(1, 1, 0),
    rgb(1, 0, 1),
    rgb(0, 1, 1),
  ];

  let i = -1;
  for (const key of keys) {
    i++;
    const v = labelObjects[key];

    const fontHeight = timesRomanFont.heightAtSize(v.fontSize);
    // const width = v.width;
    // const height = v.height;
    // const x = v.x;
    // const y = v.y;
    const scaleFactor = 1.3;
    const width = v.width / scaleFactor;
    const height = v.height / scaleFactor;
    // const iy = pageH - v.y;
    const x = v.x / scaleFactor - width / 2;
    const iy = v.y / scaleFactor + height / 2;
    const y = pageH - iy;
    const text = getValue(certificate, shareholder, key) || "";

    page.setFontSize(v.fontSize);

    if (debug) {
      page.drawRectangle({
        x: x,
        y: y,
        width: width,
        height: height,
        borderColor: colors[i % colors.length],
        borderWidth: 1,
      });
    }

    page.drawText(text, {
      x: x,
      y: y + fontHeight / 4,
      maxWidth: width,
      lineHeight: v.fontSize * 1.2,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
    });

    // const pdfBytes = await doc.save();
    // return pdfBytes;
    // const blob = new Blob([pdfBytes], { type: "application/pdf" });
    // const blobUrl = URL.createObjectURL(blob);

    //Draw label outline
    // doc.rect(x, y - paddingY, width, height).stroke();

    // doc.text(text, x, y, {
    //   width: width,
    //   height: height,
    //   align: v.align,
    // });
  }
  return doc;
};
