import { PlainDate } from "@hobson/utils";
import { z } from "zod";

export const zShares = z.number().int().positive().safe();
export const zCertificateNumber = z.number().int().positive().safe();
export const zCertificateNumbers = z.array(zCertificateNumber);
export const zShareholderId = z.string().min(1);
export const zShareholderIds = z.array(zShareholderId);
export const zDate = z.string().transform((val, ctx) => {
  try {
    return new PlainDate(val);
  } catch (e) {
    ctx.addIssue({
      code: "invalid_date",
      message: `Expected a date-like string (YYYY-MM-DD or MM/DD/YYYY), but received "${val}"`,
      path: ctx.path,
    });
    return z.NEVER;
  }
});

export const CommandSchema = z.object({
  companyId: z.string().min(1),
  date: zDate,
  note: z.string().optional(),
  tags: z.record(z.string(), z.any()).optional(),
});

export const BuySharesSchema = CommandSchema.extend({
  shareholderId: zShareholderId,
  shares: zShares,
  certificatesToCancel: zCertificateNumbers.optional(),
});

export const BuyCertificateSchema = CommandSchema.extend({
  number: zCertificateNumber,
});

export const ChangeAuthorizedSharesSchema = CommandSchema.extend({
  authorizedShares: zShares,
  note: z.string(),
});

export const CreateCompanySchema = CommandSchema.extend({
  name: z.string().min(1),
  authorizedShares: zShares,
  issuedShares: zShares,
  startingCertificateNumber: zCertificateNumber.optional(),
});

export const ForwardSplitStockSchema = CommandSchema.extend({
  multiple: z.number().int().safe().min(2),
  note: z.string(),
});

export const ReissueCertificateSchema = CommandSchema.extend({
  number: zCertificateNumber,
  note: z.string(),
});

export const SellSharesSchema = CommandSchema.extend({
  shareholderId: zShareholderId,
  shares: zShares,
  certNumberGuard: zCertificateNumber.optional(),
});

export const ImportCertificateSchema = SellSharesSchema.extend({
  certificateNumber: zCertificateNumber,
});

const zTransferSharesToCertPayload = z.object({
  shareholderId: zShareholderId,
  shares: zShares,
});
export const TransferSharesSchema = CommandSchema.extend({
  fromCerts: zCertificateNumbers,
  to: z.array(zTransferSharesToCertPayload).min(1),
});

export const VoidCertificateSchema = CommandSchema.extend({
  number: zShares,
  note: z.string(),
});

export const SetIssuedSharesSchema = CommandSchema.extend({
  issuedShares: zShares,
  note: z.string(),
});
