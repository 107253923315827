import { useMemo } from "react";
import { CommandInput } from "stocksmith";
import { TxFormSchema } from "../lib/useActionProcessor.js";
import { useCompanyData } from "../lib/useCompanyData.js";
import { trpc } from "../utils/trpcClient.js";
import { useCurrentCompany } from "../utils/useCompany.js";

export function parseTxCommands(companyId: string, data: TxFormSchema) {
  const commands = [] as CommandInput[];

  if (data.certificatesToBuyBack.length > 0) {
    for (let i = 0; i < data.certificatesToBuyBack.length; i++) {
      const cert = data.certificatesToBuyBack[i];
      commands.push({
        type: "BuyCertificate",
        payload: {
          companyId,
          number: cert.number,
          date: data.date,
          note: data.notes,
        },
      });
    }
  }

  if (data.certificatesToIssue.length > 0) {
    for (let i = 0; i < data.certificatesToIssue.length; i++) {
      const cert = data.certificatesToIssue[i];
      commands.push({
        type: "SellShares",
        payload: {
          shareholderId: cert.shareholderId,
          shares: cert.shares,
          companyId,
          date: data.date,
          note: data.notes,
        },
      });
    }
  }
  return commands;
}

export function useCommands(data: TxFormSchema) {
  const { companyId } = useCompanyData();
  return parseTxCommands(companyId, data);
}

export function usePreview(commands: CommandInput[]) {
  const { companyId, company } = useCurrentCompany();
  const previewQuery = trpc.stocks.previewCommands.useQuery({
    companyId,
    commands,
  });

  const newEvents = previewQuery.data;

  const previewCompany = useMemo(() => {
    const previewCo = company.clone();
    previewCo.addEvents(newEvents || []);
    return previewCo;
  }, [company, newEvents]);

  return {
    isLoading: previewQuery.status === "loading",
    newEvents: newEvents || [],
    previewCompany,
  };
}
