import { Box, Button, Stack } from "@chakra-ui/react";
import { useCompanies } from "../utils/useCompany.js";
import { useGlobalDisclosure } from "./globals.js";

type RequireCompanyProps = {
  children: React.ReactNode;
  showSelector?: boolean;
};

export function RequireCompany({
  children,
  showSelector,
}: RequireCompanyProps) {
  const { currentCompanyId, setCurrentCompanyId, companies } = useCompanies();
  const { onOpen } = useGlobalDisclosure("addCompanyModal");

  if (!currentCompanyId && showSelector) {
    return (
      <Box>
        <Stack>
          {companies.map((company) => {
            return (
              <Button
                key={company.id}
                onClick={() => {
                  setCurrentCompanyId(company.id);
                }}
              >
                {company.name}
              </Button>
            );
          })}
          <Button onClick={onOpen}>Add New Company</Button>
        </Stack>
      </Box>
    );
  } else if (!currentCompanyId) {
    return null;
  } else {
    return <>{children}</>;
  }
}
