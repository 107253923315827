export const randomPeople = [
  {
    first_name: "Emma",
    last_name: "Smith",
  },
  {
    first_name: "Liam",
    last_name: "Johnson",
  },
  {
    first_name: "Olivia",
    last_name: "Anderson",
  },
  {
    first_name: "Noah",
    last_name: "Thompson",
  },
  {
    first_name: "Ava",
    last_name: "Smith",
  },
  {
    first_name: "Sophia",
    last_name: "Johnson",
  },
  {
    first_name: "Isabella",
    last_name: "Anderson",
  },
  {
    first_name: "Mia",
    last_name: "Thompson",
  },
  {
    first_name: "Charlotte",
    last_name: "Smith",
  },
  {
    first_name: "Amelia",
    last_name: "Johnson",
  },
  {
    first_name: "Harper",
    last_name: "Anderson",
  },
  {
    first_name: "Evelyn",
    last_name: "Thompson",
  },
  {
    first_name: "Abigail",
    last_name: "Smith",
  },
  {
    first_name: "Emily",
    last_name: "Johnson",
  },
  {
    first_name: "Elizabeth",
    last_name: "Anderson",
  },
  {
    first_name: "Mila",
    last_name: "Thompson",
  },
  {
    first_name: "Ella",
    last_name: "Smith",
  },
  {
    first_name: "Avery",
    last_name: "Johnson",
  },
  {
    first_name: "Sofia",
    last_name: "Anderson",
  },
  {
    first_name: "Camila",
    last_name: "Thompson",
  },
  {
    first_name: "Aria",
    last_name: "Smith",
  },
  {
    first_name: "Scarlett",
    last_name: "Johnson",
  },
  {
    first_name: "Victoria",
    last_name: "Anderson",
  },
  {
    first_name: "Madison",
    last_name: "Thompson",
  },
  {
    first_name: "Luna",
    last_name: "Smith",
  },
  {
    first_name: "Grace",
    last_name: "Johnson",
  },
  {
    first_name: "Chloe",
    last_name: "Anderson",
  },
  {
    first_name: "Penelope",
    last_name: "Thompson",
  },
  {
    first_name: "Layla",
    last_name: "Smith",
  },
  {
    first_name: "Riley",
    last_name: "Johnson",
  },
  {
    first_name: "Zoey",
    last_name: "Anderson",
  },
  {
    first_name: "Nora",
    last_name: "Thompson",
  },
  {
    first_name: "Lily",
    last_name: "Smith",
  },
  {
    first_name: "Eleanor",
    last_name: "Johnson",
  },
  {
    first_name: "Hannah",
    last_name: "Anderson",
  },
];

export const randomNames = randomPeople.map((p) => {
  return `${p.first_name} ${p.last_name}`;
});
