import { Box, Divider, HStack, Heading, Stack, Text } from "@chakra-ui/react";
import { PlainDate } from "@hobson/utils";
import { Company, Event } from "stocksmith";
import { Link } from "../components/Link.js";
import { ShareholderNameCell } from "../components/ShareholderNameCell.js";
import { ShareholderProfile, useShareholders } from "../lib/useShareholders.js";
import { useCurrentCompany, useEventsByDate } from "../utils/useCompany.js";
import { VariableSizeList as List } from "react-window";

export function LedgerPage() {
  const { company } = useCurrentCompany();
  const { profilesById } = useShareholders();

  const { sortedDateKeys, eventsByDate } = useEventsByDate(company);

  //TODO, re-render this timeline but using sortedDateKeys and eventsByDate
  //
  //Basically, want to show a timeline of events, grouped by date

  const colors = ["blue.100", "green.100"];

  return (
    <Stack>
      {sortedDateKeys.map((dateKey, i) => {
        const events = eventsByDate[dateKey];
        return (
          <Box
            key={dateKey}
            bg={colors[i % colors.length]}
            p={2}
            borderRadius="5"
            boxShadow="sm"
          >
            <Heading size="lg" id={dateKey}>
              {new PlainDate(dateKey).formatUSA()}
            </Heading>
            <Box>
              {events.map((event) => {
                return (
                  <Box key={event.id}>
                    <Divider borderColor="black" my="2" />
                    <EventData
                      event={event}
                      profilesById={profilesById}
                      company={company}
                      key={event.id}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Stack>
  );
}

type EventArgs = {
  event: Event;
  profilesById: Record<string, ShareholderProfile>;
  company: Company;
};

export function EventData(args: EventArgs) {
  if (args.event.type === "CompanyCreated") {
    return <CompanyCreatedEvent {...args} />;
  } else if (args.event.type === "CertificateIssued") {
    return <CertificateIssuedEvent {...args} />;
  } else if (args.event.type === "CertificateCanceled") {
    return <CertificateCanceledEvent {...args} />;
  } else if (args.event.type === "CertificateVoided") {
    return <CertificateVoidedEvent {...args} />;
  } else if (args.event.type === "CertificateImported") {
    return <CertificateIssuedEvent {...args} />;
  } else if (args.event.type === "AuthorizedSharesChanged") {
    return <AuthorizedSharesChangedEvent {...args} />;
  } else if (args.event.type === "SetIssuedShares") {
    return <SetIssuedSharesEvent {...args} />;
  } else {
    return null;
  }
}

function CompanyCreatedEvent({ event }: EventArgs) {
  return (
    <Stack mb="2">
      <Heading size="sm">{event.payload.name} Created</Heading>;
      <Text>Authorized Shares: {event.payload.authorizedShares}</Text>
      <Text>Issued Shares: {event.payload.issuedShares}</Text>
      {event.payload?.note && (
        <Text color="gray.600">Note: {event.payload.note}</Text>
      )}
    </Stack>
  );
}

export function CertificateIssuedEvent({ event, profilesById }: EventArgs) {
  const shareholder = profilesById[event.payload.shareholderId];
  return (
    <Stack mb="2">
      <HStack alignItems="flex-start" spacing="6">
        <Box borderRight="solid 1px black" minW="200px" pr="6">
          <Heading size="sm">Certificate Issued</Heading>
          <Link
            to={`/certificates/${event.payload.certificateNumber}`}
            fontSize="lg"
          >
            Certificate #{event.payload.certificateNumber}
          </Link>
        </Box>
        <Box borderRight="solid 1px black" pr="6" w="80px">
          <Heading size="sm">Shares</Heading>
          <Text>{event.payload.shares}</Text>
        </Box>
        <Box>
          <ShareholderNameCell shareholder={shareholder} />
        </Box>
      </HStack>
      {event.payload?.note && (
        <Text color="gray.600">Note: {event.payload.note}</Text>
      )}
    </Stack>
  );
}

export function CertificateCanceledEvent({
  event,
  company,
  profilesById,
}: EventArgs) {
  const certificate = company.certificates[event.payload.certificateNumber];
  const shareholder = profilesById[certificate.shareholderId];
  return (
    <Stack mb="2">
      <HStack alignItems="flex-start" spacing="6">
        <Box borderRight="solid 1px black" minW="200px">
          <Heading size="sm">Certificate Canceled</Heading>
          <Link
            to={`/certificates/${event.payload.certificateNumber}`}
            fontSize="lg"
          >
            Certificate #{event.payload.certificateNumber}
          </Link>
        </Box>
        <Box borderRight="solid 1px black" pr="6" w="80px">
          <Heading size="sm">Shares</Heading>
          <Text>{certificate.shares}</Text>
        </Box>
        <Box>
          <ShareholderNameCell shareholder={shareholder} />
        </Box>
      </HStack>
      {event.payload?.note && (
        <Text color="gray.600">Note: {event.payload.note}</Text>
      )}
    </Stack>
  );
}

function CertificateVoidedEvent({ event }: EventArgs) {
  return (
    <Stack mb="2">
      <Heading size="sm">Certificate Voided</Heading>
      <Text>Certificate Number: {event.payload.number}</Text>
      {event.payload?.note && (
        <Text color="gray.600" mt="-3">
          Note: {event.payload.note}
        </Text>
      )}
    </Stack>
  );
}

function AuthorizedSharesChangedEvent({ event }: EventArgs) {
  return (
    <Stack mb="2">
      <Heading size="sm">Authorized Shares Changed</Heading>
      <Text>Authorized Shares: {event.payload.authorizedShares}</Text>
      {event.payload?.note && (
        <Text color="gray.600">Note: {event.payload.note}</Text>
      )}
    </Stack>
  );
}

function SetIssuedSharesEvent({ event }: EventArgs) {
  return (
    <Stack mb="2">
      <Heading size="sm">Issued Shares Changed</Heading>
      <Text>Issued Shares: {event.payload.number}</Text>
      {event.payload?.note && (
        <Text color="gray.600">Note: {event.payload.note}</Text>
      )}
    </Stack>
  );
}

export default LedgerPage;
