import { ColorProps, Flex, Link, Text } from "@chakra-ui/react";
import React from "react";

type Props = {
  address?: {
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
  };
  color?: ColorProps["color"];
};

export function Address({ address, color = "blue.500" }: Props) {
  if (!address) return null;
  const { address1, address2, city, state, zip } = address;

  const searchTerm = [address1, city, state, zip].filter(Boolean).join(",");

  const citystatezip = `${city}, ${state} ${zip}`.trim();
  const addressHtml = [address1, address2, citystatezip]
    .filter(Boolean)
    .join("\n");
  return (
    <Flex align="center">
      <Link
        href={`https://maps.google.com?q=${encodeURI(searchTerm)}`}
        color={color}
        isExternal
      >
        <Text lineHeight={1.125} whiteSpace="pre-line">
          {addressHtml}
        </Text>
      </Link>
    </Flex>
  );
}

export default Address;
