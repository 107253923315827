import { Heading, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const ShareholderNameCell = ({ shareholder }: { shareholder: any }) => {
  const name = [
    shareholder.firstName,
    shareholder.middleName,
    shareholder.lastName,
  ]
    .filter(Boolean)
    .join(" ");
  return (
    <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
      <Link to={`/shareholders/${shareholder.id}`}>
        <Heading
          size="sm"
          overflowWrap="break-word"
          color="blue.600"
          _hover={{ textDecoration: "underline" }}
        >
          {name}
        </Heading>
      </Link>
      {shareholder.trustee && (
        <Text fontStyle="italic" mt="1px">
          {shareholder.trustee}
        </Text>
      )}
    </div>
  );
};
