// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
//
// Writing these report files in js since typescript typing for write-to-excel package just
// doesn't seem to work right
import { Counter, PlainDate } from "@hobson/utils";
import writeXlsxFile from "write-excel-file";

export async function createShareholdersReport(shareholders) {
  const schemas = [
    {
      header: "First Name",
      value: (row) => row.firstName,
    },
    {
      header: "Middle Name",
      value: (row) => row.middleName,
    },
    {
      header: "Last Name",
      value: (row) => row.lastName,
    },
    {
      header: "Trustee",
      value: (row) => row.trustee,
    },
    {
      header: "Email",
      value: (row) => row.email,
    },
    {
      header: "Home Phone",
      value: (row) => row.homePhone,
    },
    {
      header: "Mobile Phone",
      value: (row) => row.mobilePhone,
    },
    {
      header: "Work Phone",
      value: (row) => row.workPhone,
    },
    {
      header: "Home Fax",
      value: (row) => row.homeFax,
    },
    {
      header: "Work Fax",
      value: (row) => row.workFax,
    },
    {
      header: "Address Line 1",
      value: (row) => row.addressLine1,
    },
    {
      header: "Address Line 2",
      value: (row) => row.addressLine2,
    },
    {
      header: "City",
      value: (row) => row.city,
    },
    {
      header: "State",
      value: (row) => row.state,
    },
    {
      header: "Zip",
      value: (row) => row.zip,
    },
    {
      header: "Total Shares",
      value: (row) => row.ownershipSummary?.totalShares,
      type: Number,
    },
    {
      header: "Ownership Percent",
      value: (row) => row.ownershipSummary?.ownershipPercent,
      type: Number,
    },
  ];

  const colLengths = new Counter(0);

  const headers = schemas.map((s) => {
    colLengths.set(s.header, s.header.length);
    return {
      type: String,
      value: s.header,
      fontWeight: "bold",
    };
  });

  const dataRows = shareholders.map((shareholder) => {
    return schemas.map((schema) => {
      const value = schema.value(shareholder) as string;
      const valLength = value?.length || 0;
      if (valLength > colLengths.get(schema.header)) {
        colLengths.set(schema.header, valLength);
      }
      return {
        type: schema.type || String,
        value,
      };
    });
  });

  const colConfigs = schemas.map((schema) => {
    const colLength = colLengths.get(schema.header);
    return {
      width: colLength,
    };
  });

  const date = new PlainDate();
  await writeXlsxFile([headers, ...dataRows], {
    columns: colConfigs,
    fileName: `shareholders_${date.toString()}.xlsx`,
  });
}
