import { Stack } from "@chakra-ui/react";
import NewTransaction from "../components/NewTransaction.js";

export function TransactionsPage() {
  return (
    <Stack>
      <NewTransaction />
    </Stack>
  );
}

export default TransactionsPage;
