import { FormErrorMessage, Stack, StackProps } from "@chakra-ui/react";
import { FieldError, FieldErrors } from "react-hook-form";

type FieldErrorMessagesProps = {
  errors: FieldErrors;
} & StackProps;

export function FieldErrorMessages(props: FieldErrorMessagesProps) {
  const { errors, ...stackProps } = props;

  if (!Array.isArray(props.errors)) {
    const error = errors as unknown as FieldError;
    return (
      <Stack {...stackProps}>
        <FieldErrorMessage error={error} />
      </Stack>
    );
  } else {
    return (
      <Stack {...stackProps}>
        {props.errors.map((error, index) => {
          return <FieldErrorMessage key={`error-${index}`} error={error} />;
        })}
      </Stack>
    );
  }
}

type FieldErrorMessage = {
  error: FieldError;
};

function FieldErrorMessage(props: FieldErrorMessage) {
  const errorMessage = props.error?.message || "Unknown Error";

  return <FormErrorMessage>{errorMessage}</FormErrorMessage>;
}

export default FieldErrorMessages;
