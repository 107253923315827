import { atom, useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

//TODO: Consider if it makes more sense to have this coded into the URL as a prefix?
export const companies = ["salinas", "santa-maria", "watsonville"] as const;
export const companyIdAtom = atomWithStorage<string | null>("companyId", null);

export const currentCompanyIdAtom = atom((get) => {
  const companyId = get(companyIdAtom);
  if (!companyId) {
    throw new Error("Global Company ID not set");
  }
  return companyId;
});

export const addCompanyModalOpenAtom = atom(false);

export const globalDisclosureStateAtom = atom<Record<string, boolean>>({});

type GlobalModalNames =
  | "addShareholderModal"
  | "addCompanyModal"
  | "selectCompanyModal";

export const useGlobalDisclosure = (name: GlobalModalNames) => {
  const [state, setState] = useAtom(globalDisclosureStateAtom);
  const isOpen = state[name] || false;
  const onOpen = () => {
    setState((state: Record<string, boolean>) => ({ ...state, [name]: true }));
  };
  const onClose = () => {
    setState((state: Record<string, boolean>) => ({ ...state, [name]: false }));
  };
  return { isOpen, onOpen, onClose };
};
