import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  LinkOverlay as ChakraLinkOverlay,
  LinkOverlayProps as ChakraLinkOverlayProps,
  LinkBox as ChakraLinkBox,
  LinkBoxProps as ChakraLinkBoxProps,
} from "@chakra-ui/react";
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

export type LinkProps = ChakraLinkProps & {
  to: RouterLinkProps["to"];
  isActive?: boolean;
  children?: React.ReactNode;
  reloadDocument?: boolean;
};

export function Link({ to, isActive, children, ...rest }: LinkProps) {
  return (
    <ChakraLink
      to={to}
      as={RouterLink}
      color={isActive ? "blue.700" : "blue.600"}
      fontWeight={isActive ? "bold" : undefined}
      _hover={{
        textDecoration: "underline",
      }}
      {...rest}
    >
      {children}
    </ChakraLink>
  );
}

export type LinkOverlayProps = ChakraLinkOverlayProps & {
  to: RouterLinkProps["to"];
  children?: React.ReactNode;
};

export function LinkOverlay({ to, ...rest }: LinkOverlayProps) {
  return <ChakraLinkOverlay to={to} as={RouterLink} {...rest} />;
}

export function LinkBox(props: ChakraLinkBoxProps) {
  return (
    <ChakraLinkBox
      transition="transform 0.2s"
      _hover={{
        transform: "scale(1.1)",
      }}
      {...props}
    />
  );
}
