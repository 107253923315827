import { PlainDate } from "@hobson/utils";
import { CommandInput } from "stocksmith";
import { z } from "zod";

const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/20\d{2}$/;

export const txFormSchema = z.object({
  date: z.string().regex(dateRegex, "Invalid date"),
  notes: z.string(),
  companyId: z.string(),
  certificatesToBuyBack: z.array(
    z.object({
      shareholderId: z.string().optional(),
      number: z.coerce.number().int().positive("Select a certificate").safe(),
    })
  ),
  certificatesToIssue: z.array(
    z.object({
      shareholderId: z.string().min(1, "Select a shareholder"),
      shares: z.coerce.number().int().positive().safe().min(1),
    })
  ),
});
export type TxFormSchema = z.infer<typeof txFormSchema>;

export function processNewTransaction(data: TxFormSchema) {
  const commands: CommandInput[] = [];

  for (const cert of data.certificatesToBuyBack) {
    commands.push({
      type: "BuyCertificate",
      payload: {
        companyId: data.companyId,
        number: cert.number,
        date: new PlainDate(data.date).toString(),
      },
    });
  }
  for (const cert of data.certificatesToIssue) {
    commands.push({
      type: "SellShares",
      payload: {
        shareholderId: cert.shareholderId,
        shares: cert.shares,
        companyId: data.companyId,
        date: new PlainDate(data.date).toString(),
      },
    });
  }

  return commands;
}
