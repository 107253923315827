import { useMemo } from "react";
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from "react-hook-form";
import { GroupBase } from "react-select";
import ReactSelectInput, {
  ReactSelectInputProps,
} from "../controls/ReactSelectInput.js";

type ReactSelectFormControlProps<
  T,
  Option,
  isMulti extends boolean,
  Group extends GroupBase<Option>
> = {
  name: Path<T>;
  label: string;
} & ReactSelectInputProps<Option, isMulti, Group>;

export function ReactSelectInputFormControl<
  T extends FieldValues,
  Option = unknown,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: ReactSelectFormControlProps<T, Option, false, Group>) {
  const formCtx = useFormContext<T>();

  const { name, label, ...selectProps } = props;

  const { field } = useController({
    name: props.name,
    control: formCtx.control,
  });

  const selectedValue = useMemo(() => {
    if (!selectProps.options) return null;
    return selectProps.options.find((o: any) => o.value === field.value) as any;
  }, [field.value, selectProps.options]);

  const handleChange = (option: any) => {
    formCtx.setValue(props.name, option.value);
  };

  formCtx.formState.errors; //TODO... this seems required to get errors to show up???
  const errors = formCtx.getFieldState(props.name).error;

  return (
    <ReactSelectInput
      name={name}
      label={label}
      onChange={handleChange}
      value={selectedValue}
      onBlur={field.onBlur}
      errors={errors}
      {...selectProps}
    />
  );
}

export function ReactMultiSelectInputFormControl<
  T extends FieldValues,
  Option = unknown,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: ReactSelectFormControlProps<T, Option, true, Group>) {
  const formCtx = useFormContext<T>();

  const { name, label, ...selectProps } = props;

  const { field } = useController({
    name: props.name,
    control: formCtx.control,
  });

  const selectedValue = useMemo(() => {
    if (!selectProps.options) return null;
    return selectProps.options.find((o: any) => o.value === field.value) as any;
  }, [field.value, selectProps.options]);

  const handleChange = (options: any) => {
    const selectedValues = options.map((o: any) => o.value);
    formCtx.setValue(field.name, selectedValues);
  };
  formCtx.formState.errors; //TODO... this seems required to get errors to show up???
  const errors = formCtx.getFieldState(props.name).error;

  return (
    <ReactSelectInput
      name={name}
      label={label}
      onChange={handleChange}
      value={selectedValue}
      onBlur={field.onBlur}
      errors={errors}
      {...selectProps}
      isMulti={true}
    />
  );
}

export default ReactSelectInputFormControl;
