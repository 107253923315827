import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import Select, { GroupBase, Props } from "react-select";
import FieldErrorMessages from "../components/FieldErrorMessages.js";

export type ReactSelectInputProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> & {
  helpText?: string;
  label: string;
  errors?: any;
};

export function ReactSelectInput<
  Option,
  isMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: ReactSelectInputProps<Option, isMulti, Group>) {
  const { name, label, errors, helpText, ...selectProps } = props;

  const hasErrors = Boolean(errors);

  return (
    <FormControl isInvalid={hasErrors}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select id={name} {...selectProps} />
      <FieldErrorMessages errors={errors} />
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
}

export default ReactSelectInput;
