import {
  Checkbox,
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Link } from "../components/Link.js";
import { ShareholderNameCell } from "../components/ShareholderNameCell.js";
import { useCertificateConfig } from "../lib/useCertificateConfig.js";
import { useShareholders } from "../lib/useShareholders.js";
import ReportViewer from "../reports/pdf/ReportViewer.js";
import { certificateReport } from "../reports/pdf/certificate.js";
import { useCurrentCompany } from "../utils/useCompany.js";
import NotFoundPage from "./NotFoundPage.js";

export function CertificatePage() {
  const params = useParams() as { certificateNumber: string };
  const [searchParams] = useSearchParams();
  const [showImage, setShowImage] = useState(false);
  const certificateNumber = parseInt(params.certificateNumber, 10);
  const { company } = useCurrentCompany();
  const { profilesById } = useShareholders();
  const certificate = company.certificates[certificateNumber];
  const { config } = useCertificateConfig();

  const shareholder = profilesById[certificate?.shareholderId];
  const labelObjects = config?.labelObjects;
  const args = useMemo(() => {
    return {
      certificate,
      shareholder,
      labelObjects: labelObjects,
      showImage,
    };
  }, [certificate, showImage, shareholder, labelObjects]);
  //FIXME... don't like this pattern of having to memoize args... should refactor to a better one somehow

  if (!certificate) {
    return <NotFoundPage />;
  }

  return (
    <Container maxW="1400px" margin={0}>
      <Link to="/certificates">← Back to All Certificates</Link>

      <SimpleGrid columns={{ sm: 1, xl: 2 }} spacing={4}>
        <Stack>
          <Table>
            <Tbody>
              <Tr>
                <Td>
                  <Heading size="md">Certificate Number</Heading>
                </Td>
                <Td>
                  <Heading size="md">{certificateNumber}</Heading>
                </Td>
              </Tr>
              <Tr>
                <Td>Shareholder</Td>
                <Td>
                  <ShareholderNameCell shareholder={shareholder} />
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text>Number of Shares</Text>
                </Td>
                <Td>
                  <Text>{certificate.shares}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>State</Td>
                <Td>{certificate.state}</Td>
              </Tr>
              <Tr>
                <Td>
                  <Text>Date Issued</Text>
                </Td>
                <Td>
                  <Text>{certificate.issuedAt?.formatUSA()}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text>Date Canceled</Text>
                </Td>
                <Td>
                  <Text>{certificate.canceledAt?.formatUSA()}</Text>
                  <Text>{certificate.canceledReason}</Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Stack>
        <Stack border="solid 1px black" padding="4" height="600px">
          <Checkbox
            size="lg"
            defaultChecked
            isChecked={showImage}
            onChange={(e) => {
              setShowImage(e.target.checked);
            }}
          >
            Show Certificate Background
          </Checkbox>
          <ReportViewer
            generateReport={certificateReport}
            args={args}
            height="600px"
            filename={`certificate-${certificateNumber}.pdf`}
          />
          {/* <Box maxW="1000px" width="100%">
            {blobUrl && <iframe src={blobUrl} width="100%" height="550px" />}
          </Box> */}
        </Stack>
      </SimpleGrid>
    </Container>
  );
}

export default CertificatePage;
