import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Stack,
  Switch,
  SwitchProps,
} from "@chakra-ui/react";
import React from "react";

type Props = SwitchProps & {
  helpText?: string;
  label: string;
  error?: {
    message?: string;
  };
};

export const SwitchInput = React.forwardRef((props: Props, ref: any) => {
  const { helpText, label, error, name, ...rest } = props;
  return (
    <FormControl isInvalid={Boolean(error)}>
      <Stack direction="row" alignItems="center">
        <Switch
          id={name}
          ref={ref}
          name={name}
          borderColor={error ? "red.500" : undefined}
          {...rest}
        />
        <FormLabel htmlFor={name}>{label}</FormLabel>
      </Stack>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
});

export default SwitchInput;
