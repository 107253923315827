import { Box, ColorProps, Heading, Stack, Text } from "@chakra-ui/react";
import { isValidElement } from "react";

interface Props {
  label: string;
  value: string | React.ReactNode;
  helper?: string;
  valueColor?: ColorProps["color"];
}
export const Stat = (props: Props) => {
  const { label, value, helper, valueColor, ...boxProps } = props;

  const statValue = isValidElement(value) ? (
    value
  ) : (
    <Heading size={{ base: "sm", md: "md" }} color={valueColor}>
      {value}
    </Heading>
  );

  return (
    <Box
      px={{ base: "4", md: "6" }}
      py={{ base: "5", md: "6" }}
      bg="bg.surface"
      borderRadius="lg"
      boxShadow="sm"
      {...boxProps}
    >
      <Stack>
        <Text textStyle="sm" color="fg.muted">
          {label}
        </Text>
        {statValue}
        {helper && (
          <Text textStyle="xs" color="fg.muted">
            {helper}
          </Text>
        )}
      </Stack>
    </Box>
  );
};
