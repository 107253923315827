import {
  Box,
  Divider,
  HStack,
  Heading,
  IconButton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { fullName, toPercent } from "@hobson/utils";
import { useParams } from "react-router-dom";
import { certificatesToTransactions } from "stocksmith";
import Address from "../components/Address.js";
import { Link } from "../components/Link.js";
import { Stat } from "../components/Stat.js";
import { useCompanyData } from "../lib/useCompanyData.js";
import { ShareholderProfile } from "../lib/useShareholders.js";
import { FiEdit2, FiEye, FiTrash2 } from "react-icons/fi/index.js";
import { useShareholderModal } from "../lib/useShareholderModal.js";

export function ShareholderPage() {
  const params = useParams();
  const shareholderId = params.shareholderId as string;
  const { profilesById } = useCompanyData();
  const { editShareholder } = useShareholderModal();
  const shareholder = profilesById[shareholderId];

  const name = fullName(shareholder);
  return (
    <Stack>
      <Link to="/shareholders">← Back to All Shareholders</Link>

      <Box>
        <Heading size="md" color="green.500" mb="0" lineHeight="1em">
          {name}
          <IconButton
            marginLeft="2"
            icon={<FiEdit2 fontSize="1.25rem" />}
            variant="tertiary"
            aria-label="Edit shareholder"
            onClick={() => editShareholder(shareholder.id)}
            zIndex="1"
            _hover={{ color: "green.800" }}
          />
        </Heading>
        <Heading size="sm" color="gray.500" mt="-2">
          {shareholder.trustee}
        </Heading>
      </Box>
      <Heading size="sm" mt="4">
        Details:
      </Heading>
      <Table>
        <Tbody>
          <Tr>
            <Td>First Name</Td>
            <Td>{shareholder.firstName}</Td>
          </Tr>
          <Tr>
            <Td>Middle Name</Td>
            <Td>{shareholder.middleName}</Td>
          </Tr>
          <Tr>
            <Td>Last Name</Td>
            <Td>{shareholder.lastName}</Td>
          </Tr>
          <Tr>
            <Td>Tax ID</Td>
            <Td>*****-{shareholder.taxIdLast4}</Td>
          </Tr>
          <Tr>
            <Td>Email</Td>
            <Td>
              <Link to={`mailto:${shareholder.email}`} reloadDocument>
                {shareholder.email}
              </Link>
            </Td>
          </Tr>
          <Tr>
            <Td>Home Phone</Td>
            <Td>
              <Link to={`tel:${shareholder.homePhone}`}>
                {shareholder.homePhone}
              </Link>
            </Td>
          </Tr>
          <Tr>
            <Td>Work Phone</Td>
            <Td>
              <Link to={`tel:${shareholder.workPhone}`}>
                {shareholder.workPhone}
              </Link>
            </Td>
          </Tr>
          <Tr>
            <Td>Cell Phone</Td>
            <Td>
              <Link to={`tel:${shareholder.cellPhone}`}>
                {shareholder.cellPhone}
              </Link>
            </Td>
          </Tr>
          <Tr>
            <Td>Home Fax</Td>
            <Td>
              <Link to={`tel:${shareholder.homeFax}`}>
                {shareholder.cellPhone}
              </Link>
            </Td>
          </Tr>
          <Tr>
            <Td>Work Fax</Td>
            <Td>
              <Link to={`tel:${shareholder.workFax}`}>
                {shareholder.cellPhone}
              </Link>
            </Td>
          </Tr>
          <Tr>
            <Td>Address</Td>
            <Td>
              <Address
                address={{
                  address1: shareholder.addressLine1,
                  address2: shareholder.addressLine2,
                  city: shareholder.city,
                  state: shareholder.state,
                  zip: shareholder.zip,
                }}
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <Heading size="sm">Notes:</Heading>
      <Text>{shareholder.notes}</Text>
      <Divider />

      <Ledger shareholder={shareholder} />
    </Stack>
  );
}

function Ledger({ shareholder }: { shareholder: ShareholderProfile }) {
  //TODO... get all events relevant for this shareholder?
  // Issued Events (easy)
  // Canceled Events (trickier since evnets don't include shareholderID by default)
  //
  // Are there any others really? I don't think so.
  const issuedCerts = shareholder.certsByState?.issued || [];
  const isActive = shareholder.isActive || false;
  const { transactionDates, certsByIssuedDate, certsByCanceledDate } =
    certificatesToTransactions(shareholder.allCerts || []);

  return (
    <Box>
      <Heading size="lg" color="green.500">
        Status
      </Heading>
      <HStack py="4">
        <Stat
          label="Status"
          value={isActive ? "Active Shareholder" : "Inactive Shareholder"}
          valueColor={isActive ? "green.500" : "red.500"}
        />
        <Stat
          label="Current Shares"
          value={shareholder.ownershipSummary?.totalShares}
        />
        <Stat
          label="Ownership"
          value={toPercent(shareholder?.ownershipSummary?.ownershipPercent)}
        />
      </HStack>
      <Divider />
      <Heading size="lg" color="green.500" mb="4" mt="2">
        Ledger
      </Heading>
      <Heading color="green.500" size="sm" mb="2">
        Current Issued Certificates
      </Heading>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Cert Number</Th>
              <Th>Shares</Th>
              <Th>Date Issued</Th>
            </Tr>
          </Thead>
          <Tbody>
            {issuedCerts.map((cert) => (
              <Tr key={cert.number}>
                <Td>
                  <Link to={`/certificates/${cert.number}`} key={cert.number}>
                    {cert.number}
                  </Link>
                </Td>
                <Td>{cert.shares}</Td>
                <Td>{cert.issuedAt.formatUSA()}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Heading color="green.500" size="sm" mb="4" mt="6">
        Historical Transactions
      </Heading>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Cert Numbers Issued</Th>
              <Th>Cert Numbers Canceled</Th>
              <Th>Total Shares Issued</Th>
              <Th>Total Shares Canceled</Th>
              <Th>Net Change</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactionDates.map((date) => {
              const totalSharesIssued = certsByIssuedDate[date]?.reduce(
                (sum, cert) => sum + cert.shares,
                0
              );
              const totalSharesCanceled = certsByCanceledDate[date]?.reduce(
                (sum, cert) => sum + cert.shares,
                0
              );
              const netChange = totalSharesIssued - totalSharesCanceled || "";
              return (
                <Tr key={date}>
                  <Td>
                    <Link to={`/ledger#${date}`}>
                      <Heading size="sm">{date}</Heading>
                    </Link>
                  </Td>
                  <Td>
                    {certsByIssuedDate[date]?.map((cert) => (
                      <Link
                        to={`/certificates/${cert.number}`}
                        key={cert.number}
                        display="block"
                      >
                        {cert.number}
                      </Link>
                    ))}
                  </Td>
                  <Td>
                    {certsByCanceledDate[date]?.map((cert) => (
                      <Link
                        to={`/certificates/${cert.number}`}
                        key={cert.number}
                        display="block"
                      >
                        {cert.number}
                      </Link>
                    ))}
                  </Td>
                  <Td>{totalSharesIssued?.toLocaleString()}</Td>
                  <Td>{totalSharesCanceled?.toLocaleString()}</Td>
                  <Td>{netChange?.toLocaleString()}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ShareholderPage;
