// FIXME this code was copied from old JS app... needs better refactoring to make it better and more readable/typesafe
import { PDFDocument, PDFFont, PDFPage, StandardFonts, rgb } from "pdf-lib";
import { z } from "zod";
import { inchesToPoints } from "./pdfUtils.js";

export const mailingLabelsSchema = z.object({
  pageWidth: z.coerce.number(),
  pageHeight: z.coerce.number(),
  topMargin: z.coerce.number(),
  bottomMargin: z.coerce.number(),
  leftMargin: z.coerce.number(),
  rightMargin: z.coerce.number(),
  columns: z.coerce.number(),
  rows: z.coerce.number(),
  columnGutter: z.coerce.number(),
  rowGutter: z.coerce.number(),
  fontSize: z.coerce.number(),
  showBorder: z.coerce.boolean(),
  align: z.string(),
});

export const defaultMailingLabels = {
  pageWidth: 8.5,
  pageHeight: 11,
  topMargin: 28,
  bottomMargin: 20,
  leftMargin: 5,
  rightMargin: 5,
  columns: 2,
  rows: 7,
  columnGutter: 5,
  rowGutter: 0,
  fontSize: 10,
  showBorder: false,
  align: "center",
};

export type MailingLabelsParams = z.infer<typeof mailingLabelsSchema>;

export type LabelData = {
  line1: string;
  line2?: string;
  line3?: string;
  line4?: string;
};

type Args = {
  labels: LabelData[];
  params: MailingLabelsParams;
};

export const mailingLabelsReport = async ({ labels, params }: Args) => {
  const pdfDoc = await PDFDocument.create();

  // Embed the Times Roman font
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

  const width = inchesToPoints(params.pageWidth);
  const height = inchesToPoints(params.pageHeight);
  let page = pdfDoc.addPage([width, height]);

  const labelWidth =
    (width -
      params.leftMargin -
      params.rightMargin -
      (params.columns - 1) * params.columnGutter) /
    params.columns;

  const labelHeight =
    (height -
      params.topMargin -
      params.bottomMargin -
      (params.rows - 1) * params.rowGutter) /
    params.rows;

  const labelPadding = 30;

  let x = params.leftMargin;
  let y = height - params.topMargin - labelHeight;

  for (let i = 0; i < labels.length; i++) {
    const label = labels[i];

    // Add border if showBorder is true
    if (params.showBorder) {
      page.drawRectangle({
        x,
        y,
        width: labelWidth,
        height: labelHeight,
        borderColor: rgb(0, 0, 0),
        borderWidth: 1,
      });
    }

    // Calculate text dimensions
    let textArray = [label.line1, label.line2, label.line3, label.line4].filter(
      Boolean
    ) as string[];

    //Split lines if they are too long
    textArray = textArray.flatMap((line) => {
      return wrapLines(
        line,
        helveticaFont,
        params.fontSize,
        labelWidth - labelPadding * 2
      );
    });

    const lineHeight = helveticaFont.heightAtSize(params.fontSize);
    let lineY = y + (lineHeight * textArray.length) / 4;
    if (params.align === "center") {
      for (const line of textArray) {
        drawLine(line, {
          page,
          font: helveticaFont,
          fontSize: params.fontSize,
          x,
          y: lineY,
          labelWidth,
          labelHeight,
        });
        lineY -= lineHeight;
      }
    } else {
      drawLines(textArray, {
        page,
        font: helveticaFont,
        fontSize: params.fontSize,
        x,
        y: lineY,
        labelWidth,
        labelHeight,
      });
    }

    // Move to the next column or next row if the end of the row is reached
    x += labelWidth + params.columnGutter;
    if ((i + 1) % params.columns === 0) {
      x = params.leftMargin;
      y -= labelHeight + params.rowGutter;
    }

    // break page if we are below bottom of page margin. Note, 0.1 is a fudge factor for rounding errors
    // that can happen with our floats
    if (y < 0 + params.bottomMargin - 0.01) {
      page = pdfDoc.addPage([width, height]);
      y = height - params.topMargin - labelHeight;
    }
  }

  return pdfDoc;
};

type drawTextArgs = {
  page: PDFPage;
  font: PDFFont;
  fontSize: number;
  x: number;
  y: number;
  labelWidth: number;
  labelHeight: number;
};

function drawLine(line: string, args: drawTextArgs) {
  drawLines([line], args);
}

function drawLines(lines: string[], args: drawTextArgs) {
  const lineWidths = lines.map((textLine) => {
    return args.font.widthOfTextAtSize(textLine, args.fontSize);
  });
  const textWidth = Math.max(...lineWidths);
  const text = lines.join("\n");

  // This looks like it should work, but seems a bit of... maybe we need to divide by 2
  // on the height offset?
  const textHeight = args.fontSize * 1.2 * text.split("\n").length;

  // Calculate text position for center alignment
  const textX = args.x + (args.labelWidth - textWidth) / 2;
  const textY = args.y + (args.labelHeight + textHeight) / 2 - textHeight / 4;

  // Add text to the label
  args.page.drawText(text, {
    x: textX,
    y: textY,
    size: args.fontSize,
    font: args.font,
    lineHeight: args.fontSize * 1.2,
    maxWidth: args.labelWidth,
  });
}

export const wrapLines = (
  text: string,
  font: PDFFont,
  fontSize: number,
  maxWidth: number
): string[] => {
  if (font.widthOfTextAtSize(text, fontSize) > maxWidth) {
    const words = text.split(" ");
    const lines: string[][] = [];
    let i = 0;
    lines[i] = [];
    for (let k = 0; k < words.length; k++) {
      const word = words[k];
      lines[i].push(word);
      if (font.widthOfTextAtSize(lines[i].join(" "), fontSize) > maxWidth) {
        lines[i].splice(-1); // retira a ultima palavra
        i = i + 1;
        lines[i] = [];
        lines[i].push(word);
      }
    }
    return lines.map((w) => w.join(" "));
    // paragraphs[index] = newParagraph.map((p) => p.join(' ')).join('\n')
    // return lines.map((p) => p.join(' ')).join('\n')
  } else {
    return [text];
  }
};
