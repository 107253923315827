import { Certificate, CertificateState, Company } from "../index.js";

type ShareholderProfile = {
  id: string;
};

type OwnershipSummary = {
  totalShares: number;
  ownershipPercent: number;
};

export type ShareholderWithCompanyStats<T extends ShareholderProfile> = T & {
  ownershipSummary?: OwnershipSummary;
  allCerts?: Certificate[];
  certsByState?: Record<CertificateState, Certificate[]>;
  isActive?: boolean;
};

export function mergeShareholderData<T extends ShareholderProfile>(
  shareholders: T[],
  company: Company
): ShareholderWithCompanyStats<T>[] {
  shareholders.forEach((shareholder: ShareholderWithCompanyStats<T>) => {
    const allCerts = company.getCertificatesForShareholder(shareholder.id);

    const certsByState = allCerts.reduce((acc, cert) => {
      if (!acc[cert.state]) {
        acc[cert.state] = [];
      }
      acc[cert.state].push(cert);
      return acc;
    }, {} as Record<CertificateState, typeof allCerts>);

    shareholder.allCerts = allCerts;
    shareholder.certsByState = certsByState;
    const issuedCerts = certsByState["issued"];

    if (!issuedCerts) {
      shareholder.isActive = false;
      shareholder.ownershipSummary = {
        totalShares: 0,
        ownershipPercent: 0,
      };
    } else {
      shareholder.isActive = true;
      const totalShares = issuedCerts.reduce(
        (acc, cert) => acc + cert.shares,
        0
      );
      const ownershipPercent = totalShares / company.outstandingShares;
      shareholder.ownershipSummary = {
        totalShares,
        ownershipPercent,
      };
    }
  });
  return shareholders as ShareholderWithCompanyStats<T>[];
}

export function certificatesToTransactions(certs: Certificate[]) {
  const certsByIssuedDate = certs.reduce((acc, cert) => {
    if (!acc[cert.issuedAt.toString()]) {
      acc[cert.issuedAt.toString()] = [];
    }
    acc[cert.issuedAt.toString()].push(cert);
    return acc;
  }, {} as Record<string, Certificate[]>);

  const certsByCanceledDate = certs.reduce((acc, cert) => {
    if (!cert.canceledAt) return acc;
    if (!acc[cert.canceledAt?.toString()]) {
      acc[cert.canceledAt?.toString()] = [] as Certificate[];
    }
    acc[cert.canceledAt?.toString()].push(cert);
    return acc;
  }, {} as Record<string, Certificate[]>);

  const transactionDates = [
    ...new Set([
      ...Object.keys(certsByIssuedDate),
      ...Object.keys(certsByCanceledDate),
    ]),
  ]
    .sort()
    .reverse();

  return {
    transactionDates,
    certsByIssuedDate,
    certsByCanceledDate,
  };
}
