import {
  ClerkProvider,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
  UserButton as ClerkUserButton,
  useAuth as useClerkAuth,
  useUser as useClerkUser,
  ClerkLoading,
  ClerkLoaded,
  UserProfile as ClerkUserProfilePage,
} from "@clerk/clerk-react";
import SignInPage from "./pages/SignInPage.js";
import SignUpPage from "./pages/SignUpPage.js";

export { ClerkUserProfilePage, ClerkUserButton };

export function useAuth() {
  const { signOut, getToken, isSignedIn, userId } = useClerkAuth();
  return {
    signOut,
    getToken,
    isSignedIn,
    userId,
  };
}

export function AuthProvider(props: {
  children: React.ReactNode;
  loader?: React.ReactNode;
  clerkPublishableKey: string;
}) {
  return (
    <ClerkProvider publishableKey={props.clerkPublishableKey}>
      <ClerkLoading>
        {props.loader ? props.loader : <div>Loading...</div>}
      </ClerkLoading>
      <ClerkLoaded>{props.children}</ClerkLoaded>
    </ClerkProvider>
  );
}

const SignInRoute = {
  path: "/sign-in",
  element: <SignInPage />,
};

const SignUpRoute = {
  path: "/sign-up/*",
  element: <SignUpPage />,
};

export const AuthRoutes = [SignInRoute, SignUpRoute];

export function RequireAuth(props: { children: React.ReactNode }) {
  return (
    <>
      <SignedIn>{props.children}</SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
}

export function useUser() {
  const clerkUser = useClerkUser();

  const user = {
    id: clerkUser?.user?.id,
    firstName: clerkUser?.user?.firstName,
    lastName: clerkUser?.user?.lastName,
    name: clerkUser?.user?.fullName,
    email: clerkUser?.user?.primaryEmailAddress?.emailAddress,
    isLoggedIn: clerkUser.isSignedIn,
    image: clerkUser?.user?.imageUrl,
    reload: clerkUser?.user?.reload,
  };

  return user;
}

export const UserButton = ClerkUserButton;
