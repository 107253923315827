import { FieldValues, Path, useFormContext } from "react-hook-form";
import SwitchInput from "../controls/SwitchInput.js";

type SwitchInputFormControlProps<T> = {
  name: Path<T>;
  label: string;
  helpText?: string;
};

export function SwitchInputFormControl<T extends FieldValues>(
  props: SwitchInputFormControlProps<T>
) {
  const formContext = useFormContext<T>();
  formContext.formState.errors; //TODO... this seems required to get errors to show up???
  const error = formContext.getFieldState(props.name).error;
  return (
    <SwitchInput
      label={props.label}
      {...formContext.register(props.name)}
      error={error}
      helpText={props.helpText}
    />
  );
}

export default SwitchInputFormControl;
