import { FieldValues, Path, useFormContext } from "react-hook-form";
import TextAreaInput from "../controls/TextAreaInput.js";
import { TextareaProps } from "@chakra-ui/react";

type TextAreaInputFormControlProps<T> = TextareaProps & {
  name: Path<T>;
  label: string;
  helpText?: string;
};

export function TextAreaInputFormControl<T extends FieldValues>({
  name,
  label,
  helpText,
  ...rest
}: TextAreaInputFormControlProps<T>) {
  const formContext = useFormContext<T>();
  formContext.formState.errors; //TODO... this seems required to get errors to show up???
  const error = formContext.getFieldState(name).error;

  return (
    <TextAreaInput
      {...rest}
      label={label}
      helpText={helpText}
      {...formContext.register(name)}
      error={error}
    />
  );
}

export default TextAreaInputFormControl;
