import { Box, HStack, TableContainer } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import CertificateList from "../components/CertificatesList.js";
import { Link } from "../components/Link.js";

export function CertificatesPage() {
  const [params] = useSearchParams();

  const filter = params.get("filter") || "issued";

  return (
    <Box>
      <Box mb="4">
        <HStack spacing="10" justifyContent="center">
          <Link
            to="?filter=issued"
            fontSize="xl"
            isActive={filter === "issued"}
          >
            Issued
          </Link>
          <Link
            to="?filter=canceled"
            fontSize="xl"
            isActive={filter === "canceled"}
          >
            Canceled
          </Link>
          <Link to="?filter=void" fontSize="xl" isActive={filter === "void"}>
            Void
          </Link>
          <Link to="?filter=all" fontSize="xl" isActive={filter === "all"}>
            All
          </Link>
        </HStack>
      </Box>

      <CertificateList filter={filter} />
    </Box>
  );
}

export default CertificatesPage;
