import { Box } from "@chakra-ui/react";
import { SignUp } from "@clerk/clerk-react";

export function SignUpPage() {
  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <SignUp path="/sign-up" routing="path" />
    </Box>
  );
}

export default SignUpPage;
