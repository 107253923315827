import { PlainDate } from "@hobson/utils";
import { useAtom } from "jotai";
import { useMemo } from "react";
import { Company, Event } from "stocksmith";
import { companyIdAtom, currentCompanyIdAtom } from "../lib/globals.js";
import { RouterOutput, trpc } from "./trpcClient.js";

type Shareholder = RouterOutput["shareholders"]["list"][0];
type CompanyWithShareholders = Company & { shareholders: Shareholder[] };

export function useCompanies() {
  const trpcCtx = trpc.useContext();
  const companiesQuery = trpc.stocks.listCompanyProfiles.useQuery(undefined, {
    suspense: true,
  });
  const updateCompanyMutation = trpc.stocks.updateCompanyProfile.useMutation({
    onSuccess() {
      trpcCtx.invalidate();
    },
    onError(error: any) {
      console.error(error);
      window.alert(error);
    },
  });

  const addCompanyMutation = trpc.stocks.addCompany.useMutation({
    onSuccess() {
      trpcCtx.invalidate();
    },
    onError(error: any) {
      console.error(error);
    },
  });

  const [currentCompanyId, setCurrentCompanyId] = useAtom(companyIdAtom);
  const companies = companiesQuery.data || [];
  const currentCompany = companies.find(
    (company) => company.id === currentCompanyId
  );

  return {
    companies: companiesQuery.data || [],
    updateCompanyMutation,
    addCompanyMutation,
    currentCompanyId,
    currentCompany,
    setCurrentCompanyId,
  };
}

export function useCurrentCompany() {
  const [companyId] = useAtom(currentCompanyIdAtom);
  const companyQuery = trpc.stocks.getCompanyEvents.useQuery(
    {
      companyId,
    },
    {
      suspense: true,
    }
  );
  const shareholdersQuery = trpc.shareholders.list.useQuery(undefined, {
    suspense: true,
  });
  const profileQuery = trpc.stocks.getCompanyProfile.useQuery(
    {
      companyId,
    },
    {
      suspense: true,
    }
  );

  const eventData = companyQuery.data;
  const shareholderData = shareholdersQuery.data;
  const company = useMemo(() => {
    const company = new Company(eventData || []) as CompanyWithShareholders;
    company.shareholders = shareholderData || [];
    return company;
  }, [eventData, shareholderData]);

  return {
    companyId,
    company,
    companyProfile: profileQuery.data,
    refetch: companyQuery.refetch,
  };
}

export function useEventsByDate(company: Company) {
  return useMemo(() => {
    const unsortedEvents = company.events || [];
    const eventsByDate = unsortedEvents.reduce((acc, event) => {
      const date = event.payload.date as PlainDate;
      const dateKey = date.toString();
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(event);
      return acc;
    }, {} as Record<string, Event[]>);

    const sortedDateKeys = Object.keys(eventsByDate).sort((a, b) => {
      const aDate = new PlainDate(a);
      const bDate = new PlainDate(b);
      return bDate.compare(aDate);
    });
    return { sortedDateKeys, eventsByDate };
  }, [company]);
}
