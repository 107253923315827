import { FieldValues, Path, useFormContext } from "react-hook-form";
import BooleanInput from "../controls/BooleanInput.js";

type BooleanInputFormControlProps<T> = {
  name: Path<T>;
  label: string;
};

export function BooleanInputFormControl<T extends FieldValues>(
  props: BooleanInputFormControlProps<T>
) {
  const formCtx = useFormContext<T>();
  formCtx.formState.errors; //TODO... this seems required to get errors to show up???
  const error = formCtx.getFieldState(props.name).error;
  return (
    <BooleanInput
      label={props.label}
      {...formCtx.register(props.name)}
      error={error}
    />
  );
}

export default BooleanInputFormControl;
