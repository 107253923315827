import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import { RequireAuth } from "@hobson/clerk-web-auth";
import { motion } from "framer-motion";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import AddCompanyModal from "../components/AddCompanyModal.js";
import SelectCompanyModal from "../components/SelectCompanyModal.js";
import ShareholderModal from "../components/ShareholderModal.js";
import { RequireCompany } from "../lib/RequireCompany.js";
import { Navbar } from "./Navbar.js";
import { Sidebar } from "./Sidebar.js";
import { Suspense } from "react";
import LoadingPage from "../pages/LoadingPage.js";

type PageShellProps = {
  children?: React.ReactNode;
  hideCanvas?: boolean;
};

export const PageShell = (props: PageShellProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  // const outlet = useOutlet();
  // console.log("outlet", outlet);
  const { pathname } = useLocation();

  const fadeInKey = pathname.split("/")[1] || "root";

  return (
    <>
      <Flex
        as="section"
        direction={{ base: "column", lg: "row" }}
        minHeight="100vh"
      >
        {isDesktop ? <Sidebar fixed /> : <Navbar />}
        <Box bg="bg-accent" pt={{ base: "0", lg: "3" }} flex="1" zIndex={2}>
          <Box
            bg={props.hideCanvas ? "none" : "bg-canvas"}
            borderTopLeftRadius={{ base: "none", lg: "2rem" }}
            height="full"
            p={4}
          >
            <Box
              height="full"
              p={5}
              borderTopLeftRadius={{ base: "none", lg: "1.5rem" }}
              boxShadow="base"
              bg="white"
            >
              <Suspense fallback={<LoadingPage />}>
                <RequireAuth>
                  <RequireCompany showSelector>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      key={fadeInKey}
                    >
                      <Outlet />
                    </motion.div>
                    {props.children ? props.children : null}
                  </RequireCompany>
                </RequireAuth>
              </Suspense>
            </Box>
          </Box>
        </Box>
      </Flex>
      <RequireCompany fallback={null}>
        <AddCompanyModal />
        <SelectCompanyModal />
        <ShareholderModal />
        <ScrollRestoration />
      </RequireCompany>
    </>
  );
};

// const Fixed = ({ children }: { children: React.ReactNode }) => {
//   return <Box position="fixed">{children}</Box>;
// };
