// src/isLeapYear.ts
function isLeapYear(year) {
  return year % 4 === 0 && year % 100 !== 0 || year % 400 === 0;
}

// src/PlainDate.ts
import superjson from "superjson";
var PlainDate = class _PlainDate {
  // private readonly date: Date;
  // private readonly date: string;
  day;
  month;
  year;
  constructor(date) {
    if (!date) {
      const today = /* @__PURE__ */ new Date();
      this.day = today.getDate();
      this.month = today.getMonth() + 1;
      this.year = today.getFullYear();
      return;
    }
    if (typeof date === "string") {
      const { year, month, day } = this.parseDate(date);
      this.day = day;
      this.month = month;
      this.year = year;
    } else {
      this.day = date.getDate();
      this.month = date.getMonth() + 1;
      this.year = date.getFullYear();
    }
  }
  parseDate(date) {
    const match = date.match(
      /^(\d{4})-(\d{2})-(\d{2})$|^(\d{2})\/(\d{2})\/(\d{4})$/
    );
    if (!match) {
      throw new Error("Invalid date");
    }
    let year, month, day;
    if (match[1] !== void 0) {
      year = parseInt(match[1]);
      month = parseInt(match[2]);
      day = parseInt(match[3]);
    } else if (match[4] !== void 0) {
      year = parseInt(match[6]);
      month = parseInt(match[4]);
      day = parseInt(match[5]);
    } else {
      throw new Error("Invalid date");
    }
    if (month < 1 || month > 12) {
      throw new Error("Invalid month");
    }
    if (day < 1 || day > 31) {
      throw new Error("Invalid day");
    }
    if (month === 2) {
      if (day > 29) {
        throw new Error("Invalid day");
      }
      if (day === 29 && !isLeapYear(year)) {
        throw new Error("Invalid day");
      }
    } else if ([4, 6, 9, 11].includes(month)) {
      if (day > 30) {
        throw new Error("Invalid day");
      }
    }
    return {
      year,
      month,
      day
    };
  }
  equals(other) {
    if (typeof other === "string") {
      try {
        return this.compare(new _PlainDate(other)) === 0;
      } catch (e) {
        return false;
      }
    } else {
      return this.compare(other) === 0;
    }
  }
  compare(other) {
    return this.year - other.year || this.month - other.month || this.day - other.day;
  }
  isBefore(other) {
    return this.compare(other) < 0;
  }
  isAfter(other) {
    return this.compare(other) > 0;
  }
  addDays(days) {
    const date = new Date(this.year, this.month - 1, this.day);
    date.setDate(date.getDate() + days);
    return new _PlainDate(date);
  }
  toDate() {
    return new Date(this.year, this.month - 1, this.day);
  }
  toString() {
    return `${this.year}-${this.month.toString().padStart(2, "0")}-${this.day.toString().padStart(2, "0")}`;
  }
  formatUSA() {
    return `${this.month.toString().padStart(2, "0")}/${this.day.toString().padStart(2, "0")}/${this.year}`;
  }
};
superjson.registerCustom(
  {
    isApplicable: (v) => v instanceof PlainDate,
    serialize: (v) => v.toString(),
    deserialize: (v) => new PlainDate(v)
  },
  "PlainDate"
);

// src/Counter.ts
var Counter = class {
  constructor(options = {}) {
    this.options = options;
    this.count = /* @__PURE__ */ new Map();
    this.defaultValue = options.defaultValue || 0;
    if (options.initialData) {
      options.initialData.forEach((key) => {
        this.add(key);
      });
    }
  }
  count;
  defaultValue = 0;
  add(key, value = 1) {
    const existing = this.count.get(key) || this.defaultValue;
    const newValue = existing + value;
    this.count.set(key, newValue);
  }
  set(key, value) {
    this.count.set(key, value);
  }
  clear(key) {
    this.count.delete(key);
  }
  get(key) {
    const value = this.count.get(key);
    if (value === void 0 && this.options.allowNull) {
      return null;
    }
    return value !== void 0 ? value : this.defaultValue;
  }
  forEach(callback) {
    this.count.forEach((value, key) => {
      callback(value, key);
    });
  }
  entries() {
    return this.count.entries();
  }
  map(callback) {
    const result = [];
    this.forEach((value, key) => {
      result.push(callback(value, key));
    });
    return result;
  }
  get keys() {
    return Array.from(this.count.keys());
  }
};

// src/strUtils.ts
function truncate(originalString, maxLength) {
  if (originalString.length > maxLength) {
    return originalString.slice(0, maxLength) + "...";
  }
  return originalString;
}
function fullName(object, options) {
  if (!object)
    return "";
  const { firstName, middleName, lastName } = object;
  const {
    middleStyle = "full",
    lastFirst = false,
    lastStyle = "full",
    firstStyle = "full"
  } = options || {};
  let constructedName = "";
  const getInitial = (name) => name ? name.charAt(0) + "." : "";
  const firstPart = (firstStyle === "full" ? firstName : getInitial(firstName)) || "";
  const middlePart = (middleStyle === "full" ? middleName : middleStyle === "initial" ? getInitial(middleName) : "") || "";
  const lastPart = (lastStyle === "full" ? lastName : getInitial(lastName)) || "";
  if (lastFirst) {
    if (lastPart) {
      constructedName += lastPart;
      if (firstPart || middlePart)
        constructedName += ", ";
    }
    if (firstPart)
      constructedName += firstPart + " ";
    if (middlePart)
      constructedName += middlePart;
  } else {
    if (firstPart)
      constructedName += firstPart + " ";
    if (middlePart)
      constructedName += middlePart + " ";
    if (lastPart)
      constructedName += lastPart;
  }
  return constructedName.trim();
}
function addressStr(object) {
  const { address1, address2, address3, city, state, zip } = object;
  let address = "";
  if (address1)
    address += address1 + "\n";
  if (address2)
    address += address2 + "\n";
  if (address3)
    address += address3 + "\n";
  if (city)
    address += city + ", ";
  if (state)
    address += state + " ";
  if (zip)
    address += zip;
  return address.trim();
}
function titleCase(str) {
  if (!str || !str.length)
    return "";
  return str.toLowerCase().split(" ").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
}

// src/stripNull.ts
function stripNull(obj) {
  const newObj = {};
  Object.keys(obj).forEach((k) => {
    const v = obj[k];
    newObj[k] = v === null ? void 0 : (
      // eslint-disable-next-line no-proto
      v && typeof v === "object" && v.__proto__.constructor === Object ? stripNull(v) : v
    );
  });
  return newObj;
}

// src/RamCache.ts
var RamCache = class {
  cache = /* @__PURE__ */ new Map();
  async getAsync(key) {
    const item = this.cache.get(key);
    if (!item)
      return null;
    if (item.expiry && Date.now() >= item.expiry) {
      return null;
    }
    return item.data;
  }
  async setAsync(key, data, ttlMs) {
    let expiry;
    if (ttlMs) {
      expiry = Date.now() + ttlMs;
    }
    this.cache.set(key, { data, expiry });
  }
  async getOrCacheAsync(key, fetchFn, ttlMs = 60) {
    const cachedData = await this.getAsync(key);
    if (cachedData !== null) {
      return cachedData;
    }
    const newData = await fetchFn();
    await this.setAsync(key, newData, ttlMs);
    return newData;
  }
  async getAllKeysAsync() {
    return Array.from(this.cache.keys());
  }
  async delAsync(key) {
    return this.cache.delete(key);
  }
  async delPrefix(prefix) {
    const keys = await this.getAllKeysAsync();
    for (const key of keys) {
      if (key.startsWith(prefix)) {
        await this.delAsync(key);
      }
    }
  }
  async clearExpiredDataAsync() {
    const currentTime = Date.now();
    for (const [key, item] of this.cache) {
      if (item.expiry && currentTime >= item.expiry) {
        await this.delAsync(key);
      }
    }
  }
};

// src/toPercent.ts
function toPercent(num, precision = 3) {
  if (num == null)
    return 0 .toFixed(precision) + "%";
  if (isNaN(num))
    return 0 .toFixed(precision) + "%";
  if (num === Infinity)
    return "Infinity%";
  return (num * 100).toFixed(precision) + "%";
}

// src/index.ts
import superjson2 from "superjson";
export {
  Counter,
  PlainDate,
  RamCache,
  addressStr,
  fullName,
  isLeapYear,
  stripNull,
  superjson2 as superjson,
  titleCase,
  toPercent,
  truncate
};
