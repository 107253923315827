import {
  Button,
  HStack,
  Heading,
  Stack,
  TableContainer,
  Text,
  useToast,
} from "@chakra-ui/react";
import { titleCase } from "@hobson/utils";
import { useMemo } from "react";
import { FiDownload } from "react-icons/fi/index.js";
import { useCompanyData } from "../lib/useCompanyData.js";
import { useShareholderModal } from "../lib/useShareholderModal.js";
import { createShareholdersReport } from "../reports/excel/shareholderXLSXReport.js";
import { ShareholdersTable } from "./ShareholderTable.js";

type Props = {
  filter: string;
};

function ShareholderList({ filter }: Props) {
  const { editShareholder } = useShareholderModal();
  const { shareholders, delShareholderMutation, company } = useCompanyData();
  const toast = useToast();

  const filteredShareholders = useMemo(() => {
    return shareholders.filter((s) => {
      if (filter === "all") {
        return true;
      } else if (filter === "active") {
        return s.isActive;
      } else if (filter === "inactive") {
        return !s.isActive;
      } else {
        return false;
      }
    });
  }, [shareholders, filter]);

  const filterText = titleCase(
    `${filteredShareholders.length} ${
      filter === "all" ? "" : filter
    } Shareholders`
  );

  return (
    <Stack>
      <HStack my="2" mt="4" alignItems="center">
        <Button
          onClick={async () => {
            await createShareholdersReport(filteredShareholders);
          }}
        >
          <FiDownload size={22} />
          <Text ml="2">Download as Excel</Text>
        </Button>
        <Heading color="green.600" size="md" ml="4">
          {filterText}
        </Heading>
      </HStack>
      <TableContainer>
        <ShareholdersTable
          id="shareholder-table"
          size="sm"
          width="100%"
          shareholders={filteredShareholders}
          onEditShareholder={editShareholder}
          onDeleteShareholder={(id) => {
            const certs = company.getCertificatesForShareholder(id);
            if (certs.length > 0) {
              toast({
                title: "Cannot delete shareholder",
                description:
                  "This shareholder has been issued certificates and therefore cannot be deleted.",
                status: "error",
              });
              return;
            }

            if (
              window.confirm(
                "Are you sure you want to delete this shareholder?"
              )
            ) {
              delShareholderMutation.mutate({
                id,
              });
            }
          }}
          viewLinkPath={(id) => {
            return `/shareholders/${id}`;
          }}
        />
      </TableContainer>
    </Stack>
  );
}

export default ShareholderList;
