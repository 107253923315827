import { useState, useEffect } from "react";

const useLocalStorage = <T,>(
  key: string,
  initialValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    const storedValue = localStorage.getItem(key);
    if (!storedValue) return initialValue;
    try {
      const parsed = JSON.parse(storedValue);
      return parsed as T;
    } catch (e) {
      console.error(`Error parsing ${key} from localStorage`, e);
      return initialValue;
    }
  });

  useEffect(() => {
    if (value === null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorage;
