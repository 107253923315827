import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputProps,
} from "@chakra-ui/react";
import React from "react";

type Props = InputProps & {
  helpText?: string;
  label: string;
  error?: {
    message?: string;
  };
};

export const TextInput = React.forwardRef((props: Props, ref: any) => {
  const { helpText, label, error, name, ...rest } = props;
  return (
    <FormControl isInvalid={Boolean(error)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        id={name}
        ref={ref}
        name={name}
        borderColor={error ? "red.500" : undefined}
        {...rest}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
});
TextInput.displayName = "TextInput";

export default TextInput;
