import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { stripNull } from "@hobson/utils";
import ShareholderForm from "../forms/ShareholderForm.js";
import { useShareholderModal } from "../lib/useShareholderModal.js";
import { useShareholders } from "../lib/useShareholders.js";
import { trpc } from "../utils/trpcClient.js";
import { useCompanyData } from "../lib/useCompanyData.js";

export function ShareholderModal() {
  const { addShareholderMutation, updateShareholderMutation } =
    useShareholders();

  const { isOpen, isNew, editingShareholderId, onClose } =
    useShareholderModal();

  const { profilesById } = useCompanyData();
  const existingShareholder = profilesById[editingShareholderId];

  // const existingShareholderQuery = trpc.shareholders.get.useQuery(
  //   { id: editingShareholderId },
  //   {
  //     enabled: Boolean(editingShareholderId),
  //     suspense: true,
  //   }
  // );

  const initialData = stripNull({ ...existingShareholder });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Shareholder</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          <ShareholderForm
            onSubmit={async (data) => {
              console.log("updating", data);
              if (editingShareholderId) {
                await updateShareholderMutation.mutateAsync({
                  id: editingShareholderId,
                  data,
                });
              } else {
                await addShareholderMutation.mutateAsync(data);
              }
              onClose();
            }}
            initialData={initialData}
            submitText={isNew ? "Add Shareholder" : "Update Shareholder"}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ShareholderModal;
