import { Box, Button, HStack, Stack, Text } from "@chakra-ui/react";
import { useRef } from "react";
import { FiDownload, FiMaximize } from "react-icons/fi/index.js";
import { useReport } from "./index.js";

type ReportViewerProps = {
  generateReport: any;
  args: any;
  filename?: string;
  height?: string | number;
};

export function ReportViewer({
  generateReport,
  args,
  filename,
  ...rest
}: ReportViewerProps) {
  const blobUrl = useReport(generateReport, args);
  const height = rest.height || "calc(100vh - 70px)";
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <Stack flex={1} height={height}>
      <Box>
        <HStack spacing="3">
          <Button ref={buttonRef} href={blobUrl || ""} as="a" target="_blank">
            <FiMaximize size={22} />
            <Text ml="2">Full Screen</Text>
          </Button>
          <Button
            href={blobUrl || ""}
            as="a"
            target="_blank"
            download={filename || "download.pdf"}
          >
            <FiDownload size={22} />
            <Text ml="2">Download</Text>
          </Button>
        </HStack>
      </Box>
      <Box flex={1} height={height} bg="gray.500" border="solid 1px black">
        <iframe
          key={blobUrl || ""}
          src={(blobUrl || "") + "#toolbar=0"}
          width="100%"
          height="100%"
          style={{ flex: 1 }}
        />
      </Box>
    </Stack>
  );
}

export default ReportViewer;
