import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import React from "react";

export type SelectOption = {
  key?: any;
  label: any;
  value: any;
  disabled?: boolean;
};
export type Props = {
  helpText?: string;
  label: string;
  name: string;
  placeholder?: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  onBlur?: React.FocusEventHandler<HTMLSelectElement>;
  error?: {
    message?: string;
  };
  options: SelectOption[];
  value?: string | null;
};

export const SelectInput = React.forwardRef((props: Props, ref: any) => {
  const {
    helpText,
    label,
    error,
    name,
    onChange,
    onBlur,
    placeholder,
    options,
    value,
  } = props;
  const selectOptions = options || [];
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select
        id={name}
        ref={ref}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        value={value || undefined}
      >
        {selectOptions.map((option) => {
          return (
            <option
              key={option.key || option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          );
        })}
      </Select>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
});

SelectInput.displayName = "SelectFormControl";

export default SelectInput;
