import { extendTheme } from "@chakra-ui/react";
import { Calendar } from "./calendar.js";
import { CalendarMonth } from "./month.js";
import { CalendarDay } from "./day.js";
import { CalendarControl } from "./controls.js";

export const calendarThemeComponents = {
  Calendar,
  CalendarMonth,
  CalendarDay,
  CalendarControl,
};

export const calendarTheme = extendTheme({
  components: {
    Calendar,
    CalendarMonth,
    CalendarDay,
    CalendarControl,
  },
});
