import { Box } from "@chakra-ui/react";

export function LoadingPage() {
  return (
    <Box
      flex={1}
      minH="100%"
      minW="100%"
      alignItems="center"
      justifyContent="center"
      bg="gray.50"
      display="flex"
      position="relative"
    >
      <div className="init-spinner-bg">
        <div className="init-spinner" />
      </div>
    </Box>
  );
}

export default LoadingPage;
