import { motion } from "framer-motion";
import React from "react";

export const AnimateIn = ({
  isVisible,
  children,
  duration,
}: {
  isVisible: boolean;
  duration: number;
  children: React.ReactNode;
}) => {
  if (!isVisible) return null;
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration }}
      key="children"
    >
      {children}
    </motion.div>
  );
};
