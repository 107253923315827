import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useAuth } from "@hobson/clerk-web-auth";
import { TRPCClientError } from "@trpc/client";
import { useRouteError } from "react-router-dom";

export function ErrorPage() {
  const error = useRouteError() as any;
  console.error(error);

  let isTRPCError = false;
  let trpcErrorCode = "";
  let trpcErrorStatus = 0;
  if (error instanceof TRPCClientError) {
    isTRPCError = true;
    console.log("trpc error data: ", error.data);
    trpcErrorCode = error.data?.code;
    trpcErrorStatus = error.data?.httpStatus;
  }
  const hostname = location.hostname;
  const isDev = hostname === "localhost";
  const color = isTRPCError ? "green.500" : "red.600";

  if (trpcErrorStatus === 403) {
    return (
      <ForbiddenErrorMessage
        message={error.message}
        code={trpcErrorCode}
        httpStatus={trpcErrorStatus}
      />
    );
  }

  return (
    <Box
      bg="bg-canvas"
      flex={1}
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={{ base: 0, md: 10 }}
    >
      <Container>
        <Card p={{ base: 3, md: 8 }}>
          <Stack textAlign="center" width="100%">
            <Heading size="xl" color={color}>
              Uh oh
            </Heading>
            <Heading size="md" color={color}>
              Something went wrong... please try again later or contact support.
            </Heading>
            {error.status && (
              <Heading size="md" color={color} my={4}>
                Error Code: {error.status}
              </Heading>
            )}

            <Accordion color={color} borderColor={color} allowToggle mt={4}>
              <AccordionItem>
                <AccordionButton>
                  <Heading size="md" width="100%">
                    <Flex justifyContent="flex-start" columnGap={2}>
                      <AccordionIcon />
                      Error Details
                    </Flex>
                  </Heading>
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text>{error.statusText || error.message}</Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
        </Card>
      </Container>
    </Box>
  );
}

function ForbiddenErrorMessage({
  message,
  code,
  httpStatus,
}: {
  message: string;
  code: string;
  httpStatus: number;
}) {
  const auth = useAuth();
  const color = "red.600";
  return (
    <Box
      bg="bg-canvas"
      flex={1}
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={10}
    >
      <Container>
        <Card p={8}>
          <Stack textAlign="center" width="100%">
            <Heading size="xl" color={color}>
              {httpStatus} Access Denied
            </Heading>
            <Heading size="md" color={color} my={4}>
              {message}
            </Heading>
            <Box>
              <Button
                colorScheme="red"
                onClick={async () => {
                  await auth.signOut();
                  window.location.reload();
                }}
              >
                Logout
              </Button>
            </Box>
          </Stack>
        </Card>
      </Container>
    </Box>
  );
}

export default ErrorPage;
