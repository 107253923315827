import { Avatar, Box, Button, HStack, Text } from "@chakra-ui/react";
import { useUser } from "@hobson/clerk-web-auth";
import { NavLink } from "react-router-dom";

export const UserProfile = () => {
  const { name, email, image } = useUser();

  return (
    <Button
      to="/user"
      variant="ghost-on-accent"
      justifyContent="start"
      as={NavLink}
      height="auto"
    >
      <HStack spacing="3" py="1">
        <Avatar src={image} boxSize="10" />
        <Box>
          <Text color="on-accent" fontWeight="medium" fontSize="sm">
            {name}
          </Text>
          <Text color="on-accent-muted" fontSize="sm">
            {email}
          </Text>
        </Box>
      </HStack>
    </Button>
  );
};
