import { Button, Stack } from "@chakra-ui/react";
import { useZodForm } from "@hobson/forms";
import { z } from "zod";
import { companyProfileSchema } from "../lib/schemas.js";

const companyTypes = ["LLC", "C-Corp", "S-Corp"] as const;

// Enforce MM/dd/yyyy format
const dateRegex = /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;

const schema = companyProfileSchema.extend({
  id: z.string().nonempty({ message: "Company ID is required" }),
  authorizedShares: z.coerce
    .number()
    .int()
    .positive({ message: "Authorized shares must be a positive integer" }),
  issuedShares: z.coerce
    .number()
    .int()
    .positive({ message: "Issued shares must be a positive integer" }),
  startingCertificateNumber: z.coerce.number().int().positive({
    message: "Starting certificate number must be a positive integer",
  }),
  date: z
    .string()
    .nonempty({ message: "Founding date is required" })
    .regex(dateRegex, { message: "Invalid date format, must be MM/dd/yyyy" }),
  type: z.enum(companyTypes),
});

type FormValues = z.infer<typeof schema>;

type AddCompanyFormProps = {
  defaultValues?: Partial<FormValues>;
  onSubmit: (data: FormValues) => void;
};

const companyTypeOptions = companyTypes.map((type) => ({
  label: type,
  value: type,
}));

export function AddCompanyForm(props: AddCompanyFormProps) {
  const zf = useZodForm(schema, {
    defaultValues: props.defaultValues,
  });

  return (
    <zf.Form onSubmit={props.onSubmit}>
      <Stack>
        <zf.TextInput name="name" label="Company Name" />
        <zf.TextInput name="id" label="Company ID" />
        <zf.TextInput
          name="incorporationState"
          label="State of Incorporation"
        />
        <zf.TextInput name="authorizedShares" label="Authorized Shares" />
        <zf.TextInput name="issuedShares" label="Issued Shares" />
        <zf.TextInput
          name="startingCertificateNumber"
          label="Starting Certificate Number"
        />
        <zf.DateInput name="date" label="Founding Date" clearable />
        <zf.SelectInput
          name="type"
          label="Company Type"
          options={companyTypeOptions}
        />
        <zf.TextInput name="addressLine1" label="Address Line 1" />
        <zf.TextInput name="addressLine2" label="Address Line 2" />
        <zf.TextInput name="city" label="City" />
        <zf.TextInput name="state" label="State" />
        <zf.TextInput name="zip" label="Zip" />
        <Button type="submit" colorScheme="green" my={4}>
          Submit
        </Button>
      </Stack>
    </zf.Form>
  );
}

export default AddCompanyForm;
