import { Box, Button, HStack, Icon, Text } from "@chakra-ui/react";
import { BsBuilding } from "react-icons/bs/index.js";
import { IoMdArrowDropdown } from "react-icons/io/index.js";
import { useGlobalDisclosure } from "../lib/globals.js";

type SelectCompanyButtonProps = {
  name?: string | null;
};

export function SelectCompanyButton(props: SelectCompanyButtonProps) {
  const textContent = props.name || "Select Company";
  const { onOpen } = useGlobalDisclosure("selectCompanyModal");

  return (
    <Button variant="ghost-on-accent" margin={0} maxW="100%" onClick={onOpen}>
      <HStack spacing="3" w="100%">
        <Icon as={BsBuilding} boxSize="6" color="on-accent-subtle" />
        <Text
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          minW={0}
        >
          {textContent}
        </Text>
        <Box ml="1">
          <IoMdArrowDropdown size="1.4em" />
        </Box>
      </HStack>
    </Button>
  );
}

export default SelectCompanyButton;
