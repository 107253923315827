import { Box, Button } from "@chakra-ui/react";
import { fabric } from "fabric";
import { useEffect, useRef } from "react";
import { useCertificateConfig } from "../lib/useCertificateConfig.js";
import certificateImage from "../assets/certificate.jpg";
const certWidth = 792;
const certHeight = 612;

const displayWidth = 1.3 * certWidth;
const displayHeight = 1.3 * certHeight;

export const CertificateConfigView = ({ onSave }: { onSave?: any }) => {
  const { config, saveLabels } = useCertificateConfig();
  const fabricObjects = useRef<any>({});
  const canvasRef = useRef<fabric.Canvas | null>();

  useEffect(() => {
    console.log("rendering canvas");
    if (!canvasRef.current) {
      canvasRef.current = new fabric.Canvas("fabricCanvas");
    }
    const canvas = canvasRef.current;
    canvas.clear();
    fabricObjects.current = {};

    const labelObjects = config?.labelObjects;
    const objectKeys = Object.keys(labelObjects);

    for (const key of objectKeys) {
      const c = labelObjects[key];
      console.log("rendering: ", c.placeholder, c.x, c.y);

      const rectangle = new fabric.Rect({
        fill: "rgba(255,0,0,0.2)",
        width: c.width,
        height: c.height,
        originX: "center",
        originY: "center",
      });

      const textBox = new fabric.Textbox(c.placeholder, {
        // left: c.x,
        // top: c.y,
        width: c.width,
        height: c.height,
        fontSize: c.fontSize,
        originX: "center",
        originY: "center",
        padding: 5,
        // lockScalingY: true,
        textAlign: c.align,
      });

      const group = new fabric.Group([rectangle, textBox], {
        left: c.x,
        top: c.y,
        originX: "center",
        originY: "center",
      });

      group.setControlsVisibility({
        tr: false,
        br: false,
        tl: false,
        bl: false,
        // mt: false,
        // mb: false,
      });
      fabricObjects.current[key] = group;
      canvas.add(group);
    }
  }, [config]);

  const handleSave = () => {
    saveLabels(fabricObjects.current);
    onSave();
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box
        flexShrink={0}
        flexGrow={0}
        padding="10px"
        border="solid 1px gray"
        backgroundImage={`url(${certificateImage})`}
        backgroundSize={`${displayWidth}px, ${displayHeight}px`}
        bgRepeat="no-repeat"
        // style={{
        //   backgroundSize: [displayWidth, displayHeight],
        // }}
      >
        <canvas id="fabricCanvas" width={displayWidth} height={displayHeight} />
      </Box>
      <Box flexGrow={1} backgroundColor="gray.500">
        <Button onClick={handleSave}>Save Config</Button>
      </Box>
    </Box>
  );
};
