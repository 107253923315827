import { Stack } from "@chakra-ui/react";
import { ClerkUserProfilePage, RequireAuth } from "@hobson/clerk-web-auth";
import { PageShell } from "../layout/PageShell.js";

export function UserProfilePage() {
  return (
    <RequireAuth>
      <PageShell hideCanvas>
        <Stack
          flex={1}
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <ClerkUserProfilePage />
        </Stack>
      </PageShell>
    </RequireAuth>
  );
}

export default UserProfilePage;
