import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Table,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import { useZodForm } from "@hobson/forms";
import { useFieldArray } from "react-hook-form";
import { FiMinus, FiPlus } from "react-icons/fi/index.js";

import { PlainDate } from "@hobson/utils";
import { txFormSchema, TxFormSchema } from "../lib/useActionProcessor.js";
import { useCurrentCompany } from "../utils/useCompany.js";
import CertificateField from "./CertificateField.js";

type TransactionFormProps = {
  onSubmit: (data: TxFormSchema) => void;
  onReset: (e: any) => void;
  initialValues?: Partial<TxFormSchema>;
};

export function TransactionForm(props: TransactionFormProps) {
  const { company } = useCurrentCompany();
  const zf = useZodForm(txFormSchema, {
    defaultValues: {
      date: new PlainDate().formatUSA(),
      ...(props.initialValues || {}),
      companyId: company.id,
    },
  });
  const cancelArray = useFieldArray({
    control: zf.form.control,
    name: "certificatesToBuyBack",
  });
  const issueArray = useFieldArray({
    control: zf.form.control,
    name: "certificatesToIssue",
  });

  const shareholderOptions = company.shareholders.map((shareholder) => {
    return {
      value: shareholder.id,
      label: `${shareholder.firstName} ${shareholder.lastName}`,
    };
  });

  const certificatesToBuyBack = zf.form.watch("certificatesToBuyBack") || [];
  const certificatesToIssue = zf.form.watch("certificatesToIssue") || [];

  const totalIssued = certificatesToIssue.reduce(
    (acc, curr) => acc + parseInt(curr.shares as any, 0),
    0
  );

  const totalCanceled = certificatesToBuyBack.reduce((acc, curr) => {
    const shares = company?.certificates?.[curr.number]?.shares || 0;
    return acc + shares;
  }, 0);

  const changeInTreasuryShares = totalIssued - totalCanceled;
  const treasuryChangeColor =
    changeInTreasuryShares === 0 ? undefined : "red.500";

  const { isSubmitting } = zf.form.formState;
  const submitDisabled = isSubmitting;

  return (
    <Box>
      <Box textAlign="right">
        <Button onClick={props.onReset}>Reset Form</Button>
      </Box>
      <Grid templateColumns="repeat(5, 1fr)" gap={10} my={4} mx="2">
        <GridItem w="300px" colSpan={1}>
          <Heading size="sm">Transaction Summary</Heading>
          <Table size="sm">
            <Tbody>
              <Tr>
                <Td>Shares Issued</Td>
                <Td>{totalIssued.toLocaleString()}</Td>
              </Tr>
              <Tr>
                <Td>Shares Canceled</Td>
                <Td>{totalCanceled.toLocaleString()}</Td>
              </Tr>
              <Tr>
                <Td></Td>
                <Td></Td>
              </Tr>
              <Tr>
                <Td>
                  <Heading size="xs">Change in Treasury Shares</Heading>
                </Td>
                <Td>
                  <Heading size="xs" color={treasuryChangeColor}>
                    {changeInTreasuryShares.toLocaleString()}
                  </Heading>
                </Td>
              </Tr>
            </Tbody>
          </Table>

          <Table size="sm" mt={4}>
            <Tbody></Tbody>
          </Table>
        </GridItem>
        <GridItem colSpan={4}>
          <zf.Form onSubmit={props.onSubmit}>
            <Heading fontSize="2xl" color="gray.600">
              Existing certificates to be canceled or bought back
            </Heading>
            {cancelArray.fields.map((field, index) => {
              const selectedShareholderId =
                certificatesToBuyBack[index]?.shareholderId;
              return (
                <Box key={field.id} mb={2}>
                  <HStack alignItems="flex-end">
                    <zf.ReactSelect
                      options={shareholderOptions}
                      name={`certificatesToBuyBack.${index}.shareholderId`}
                      label="Shareholder"
                    />
                    <CertificateField
                      key={selectedShareholderId}
                      shareholderId={selectedShareholderId}
                      filterState="issued"
                      name={`certificatesToBuyBack.${index}.number`}
                      label="Certificate"
                    />
                    <Button
                      onClick={() => cancelArray.remove(index)}
                      colorScheme="red"
                      flexShrink={0}
                    >
                      <FiMinus />
                    </Button>
                  </HStack>
                </Box>
              );
            })}
            <Button
              onClick={() =>
                cancelArray.append({ number: 0, shareholderId: "" })
              }
              my={2}
            >
              <FiPlus />
            </Button>

            <Heading fontSize="2xl" color="gray.600" my={2}>
              New certificates to be issued or sold
            </Heading>
            {issueArray.fields.map((field, index) => {
              return (
                <Box key={field.id}>
                  <HStack alignItems="flex-end">
                    <zf.ReactSelect
                      options={shareholderOptions}
                      name={`certificatesToIssue.${index}.shareholderId`}
                      label="Shareholder"
                    />
                    <zf.TextInput
                      name={`certificatesToIssue.${index}.shares`}
                      label="Shares"
                    />
                    <Button
                      onClick={() => issueArray.remove(index)}
                      colorScheme="red"
                      flexShrink={0}
                    >
                      <FiMinus />
                    </Button>
                  </HStack>
                </Box>
              );
            })}
            <Button
              my={2}
              onClick={() =>
                issueArray.append({ shareholderId: "", shares: 1 })
              }
            >
              <FiPlus />
            </Button>
            <Heading fontSize="2xl" color="gray.600" my={2}>
              Additional Transaction Info
            </Heading>
            <zf.DateInput name="date" label="Date" clearable />
            <Box mt={4}>
              <zf.TextAreaInput name="notes" label="Notes" />
            </Box>
            <zf.HiddenInput label="Company ID" name={"companyId"} />
            <Button
              type="submit"
              colorScheme="green"
              mt={4}
              width="auto"
              isDisabled={submitDisabled}
            >
              Submit Transaction
            </Button>
          </zf.Form>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default TransactionForm;
