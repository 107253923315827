import { theme as proTheme } from "./chakra-pro-theme/index.js";
import { extendTheme, theme as baseTheme } from "@chakra-ui/react";
import { calendarTheme } from "@hobson/forms";

export const theme = extendTheme(
  {
    colors: { ...baseTheme.colors, brand: baseTheme.colors.green },
    fonts: {
      heading: "'Roboto', system-ui, sans-serif",
      body: "'Roboto', system-ui, sans-serif",
    },
  },
  proTheme,
  calendarTheme,
  {
    fonts: {
      heading: "'Roboto', system-ui, sans-serif",
      body: "'Roboto', system-ui, sans-serif",
    },
  }
);
