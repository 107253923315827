import { useSearchState } from "./useSearchState.js";

export function useShareholderModal() {
  const [state, setState] = useSearchState<string | null>(
    "editShareholder",
    null
  );
  const isOpen = Boolean(state);
  const isNew = state === "new";
  const isEditing = Boolean(state) && !isNew;
  const editingShareholderId = isNew ? null : state;

  const onClose = () => {
    setState(null);
  };
  const addShareholder = () => {
    setState("new");
  };
  const editShareholder = (id: string) => {
    setState(id);
  };

  return {
    isOpen,
    isEditing,
    isNew,
    editingShareholderId,
    onClose,
    editShareholder,
    addShareholder,
  };
}
