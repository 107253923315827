// FIXME this code was copied from old JS app... needs better refactoring to make it better and more readable/typesafe
import { PlainDate, fullName, toPercent } from "@hobson/utils";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Table, UserOptions } from "jspdf-autotable";
import { useParams } from "react-router-dom";
import { useCompanyData } from "../../lib/useCompanyData.js";
import { ShareholderProfile } from "../../lib/useShareholders.js";
import ReportViewer from "./ReportViewer.js";

interface jsPDFCustom extends jsPDF {
  autoTable: (options: UserOptions) => void;
  lastAutoTable: Table;
}

type Args = {
  shareholders: ShareholderProfile[];
};

//eslint-disable-next-line react-refresh/only-export-components
export const ledgerReport = async ({ shareholders }: Args) => {
  const doc = new jsPDF("p", "in", [8.5, 11]) as jsPDFCustom;
  const marginTop = 0.5;
  const marginBottom = 0.5;
  const marginLeft = 0.5;
  const marginRight = 0.5;
  // const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;

  let y = 0.5;

  let renderedShareholders = 0;
  for (const shareholder of shareholders) {
    if (shareholder?.allCerts?.length === 0) continue;

    //Start a new page for each new shareholder ledger
    if (renderedShareholders > 0) {
      doc.addPage();
      y = marginTop;
    }

    doc.setFontSize(18);
    doc.setFont("Helvetica", "normal", "bold");
    doc.text(`Ledger for ${fullName(shareholder)}`, 0.5, y);
    doc.setFont("Helvetica", "normal", "normal");
    if (shareholder.trustee) {
      y += 0.25;
      doc.setFontSize(12);
      doc.text(`Trustee: ${shareholder.trustee}`, 0.5, y);
    }
    y += 0.25;
    doc.setFontSize(10);
    doc.setTextColor("#444444");
    doc.text(`Shareholder ID: ${shareholder.id}`, 0.5, y);
    y += 0.15;
    doc.setLineWidth(0.01);
    doc.line(0.5, y, pageWidth - 0.5, y);

    y += 0.3;
    doc.setTextColor("#000000");
    doc.setFontSize(14);

    //Add Shareholder Address Lines
    doc.text(`${shareholder.addressLine1}`, 0.5, y);
    y += 0.25;
    if (shareholder.addressLine2) {
      doc.text(`${shareholder.addressLine2}`, 0.5, y);
      y += 0.25;
    }
    doc.text(
      `${shareholder.city}, ${shareholder.state} ${shareholder.zip}`,
      0.5,
      y
    );

    y += 0.6;
    doc.setFontSize(16);
    doc.setFont("Helvetica", "normal", "bold");
    doc.text(`Transaction History`, 0.5, y);
    doc.setFont("Helvetica", "normal", "normal");

    y += 0.25;

    const txLines = [] as any;
    (shareholder?.certsByState?.["issued"] || []).forEach((cert) => {
      txLines.push([
        cert.issuedAt.formatUSA(),
        cert.number,
        "Issued",
        cert.shares.toLocaleString(),
        "",
        cert.issuedAt.formatUSA(),
      ]);
    });
    (shareholder?.certsByState?.["canceled"] || []).forEach((cert) => {
      txLines.push([
        cert.canceledAt?.formatUSA(),
        cert.number,
        "Canceled",
        "",
        cert.shares.toLocaleString(),
        cert.canceledAt?.formatUSA(),
      ]);
      txLines.push([
        cert.issuedAt.formatUSA(),
        cert.number,
        "Issued",
        cert.shares.toLocaleString(),
        "",
        cert.issuedAt.formatUSA(),
      ]);
    });
    txLines.sort((a: any, b: any) => {
      return new Date(b[0]).getTime() - new Date(a[0]).getTime();
    });

    doc.autoTable({
      bodyStyles: {
        fontSize: 10,
      },
      startY: y,
      margin: {
        left: marginLeft,
        right: marginRight,
        top: marginTop,
        bottom: marginBottom,
      },
      head: [
        [
          "Tx Date",
          "Cert #",
          "action",
          "Shares Issued",
          "Shares Canceled",
          "Date Cert Issued",
        ],
      ],
      body: txLines,
      headStyles: {
        fontSize: 10,
        fillColor: [39, 116, 72],
      },
      showFoot: "lastPage",
    });

    const table = doc.lastAutoTable;
    if (table.finalY) {
      y = table.finalY + 0.3;
    }

    doc.setLineWidth(0.1);
    doc.setDrawColor(39, 116, 72);
    doc.line(marginLeft, y, pageWidth - marginLeft, y);

    y += 0.3;
    doc.setFont("Helvetica", "normal", "bold");
    doc.text(
      `Current Ownership: ${shareholder.ownershipSummary?.totalShares.toLocaleString()} Shares, ${toPercent(
        shareholder.ownershipSummary?.ownershipPercent
      )}`,
      marginLeft,
      y
    );
    doc.setFont("Helvetica", "normal", "normal");

    renderedShareholders += 1;
  }

  doc.setProperties({
    title: "Ledger Report",
  });

  return doc;
};

export function LedgerReport() {
  const params = useParams<{ shareholderId?: string }>();
  const { shareholders, company } = useCompanyData();
  let shareholdersToRender = shareholders;
  if (params.shareholderId) {
    if (params.shareholderId === "active") {
      shareholdersToRender = shareholders.filter(
        (shareholder) => shareholder.isActive
      );
    } else {
      shareholdersToRender = shareholders.filter(
        (shareholder) => shareholder.id === params.shareholderId
      );
    }
  }

  return (
    <ReportViewer
      generateReport={ledgerReport}
      args={{
        shareholders: shareholdersToRender,
      }}
      filename={`${company.id}_journal_${new PlainDate().toString()}.pdf`}
    />
  );
}
